import { Box, TextField } from '@material-ui/core'
import { MFlexBlock, MPortalDialog } from '@mprise/react-ui'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonAsyncClickHandler } from '../button/async'
import { MyOrganizationsQuery, namedOperations, useCreateOrganizationMutation } from '../graphql/generated'
import { Alerts } from '../shared/alerts'
import { MutationErrorMessage } from '../shared/apollo'
import { NotOptional } from '../shared/typescript'
import { SetProperty } from '../utils'
import { SavingSwitchPanel } from './saving-switch-panel'

type OrganizationForm = NotOptional<NotOptional<MyOrganizationsQuery['organizations']>[0]>
const emptyOrganization: OrganizationForm = {
  id: ``,
  name: ``,
  admins: [],
}

export const OrganizationNewDialog = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [form, setForm] = useState(emptyOrganization)

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const [createOrganization, createOrganizationMutation] = useCreateOrganizationMutation()

  const save: ButtonAsyncClickHandler = async e => {
    e.preventDefault()

    const org = await createOrganization({
      refetchQueries: [namedOperations.Query.MyOrganizations],
      variables: {
        name: form.name ?? `TODO`,
      },
    })

    if (org.data?.createOrganization) {
      alerts.push(alertText.created(`Organization`), `success`)
      navigate(`/organization/${org?.data?.createOrganization?.id}/tenants`, { replace: true })
    }
  }

  const handleClose = () => {
    navigate(`/organizations`, { replace: true })
  }

  return (
    <>
      <MPortalDialog.Dialog minWidth='sm' open onClose={handleClose}>
        <SavingSwitchPanel mutations={[createOrganizationMutation]}>
          <MPortalDialog.Form onSubmit={save}>
            <MPortalDialog.Header onClose={handleClose}>
              <MFlexBlock justifyContent='flex-start' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {t(`New Organization`)}
              </MFlexBlock>
            </MPortalDialog.Header>
            <MPortalDialog.Content>
              <Box paddingX={2}>
                <MutationErrorMessage mutation={createOrganizationMutation} />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label='Name'
                  fullWidth
                  value={form.name}
                  onChange={v => setForm(SetProperty(`name`, v.target.value))}
                  autoFocus
                  autoComplete='off'
                />
              </Box>
            </MPortalDialog.Content>
            <MPortalDialog.Footer>
              <MFlexBlock justifyContent='flex-end'>
                <MPortalDialog.SubmitButton>Create Organization</MPortalDialog.SubmitButton>
              </MFlexBlock>
            </MPortalDialog.Footer>
          </MPortalDialog.Form>
        </SavingSwitchPanel>
      </MPortalDialog.Dialog>
    </>
  )
}
