import { makeStyles } from '@material-ui/core'
import React from 'react'

export const ActiveStatus = ({ label, active }: { label: React.ReactNode; active?: boolean }) => {
  const classes = useStyles({ active })
  return (
    <div className={classes.root}>
      <span>{label}</span>
      <div className={classes.icon} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: `flex`,
    alignItems: `center`,
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: `inline-block`,
    borderRadius: `100%`,
    width: theme.typography.fontSize * 0.5,
    height: theme.typography.fontSize * 0.5,
    backgroundColor: ({ active }: { active?: boolean }) => (active ? `#33cc33` : `#e6980c`),
  },
}))
