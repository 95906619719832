import { Box, Breadcrumbs, Card, CardHeader, Link } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAllOrganizationsQuery, useAllTenantsQuery } from '../graphql/generated'
import { IconBreadcrumbDivider, IconTenant } from '../icons'
import { Wrapper } from '../layout/wrapper'
import { CardErrorContent } from '../shared/card-error-content'
import Protected from '../shared/protected-route'
import { RouterLink } from '../shared/router-link'
import { defined, NotOptional } from '../shared/typescript'
import { TableRouteButton } from '../table/button'
import { DataTable } from '../table/table'

export const TenantListRoute = () => {
  const { t } = useTranslation()
  const { organizationId } = useParams() as { organizationId: string }
  const navigate = useNavigate()

  const organizationQuery = useAllOrganizationsQuery()
  const organization = organizationQuery.data?.organizations?.find(x => x?.id === organizationId)
  const tenantQuery = useAllTenantsQuery()
  const tenants = tenantQuery.data?.tenants?.filter(x => x?.organization?.id === organizationId)
  const rows = tenants?.filter(defined).map(x => ({
    url: `/organization/${organizationId}/tenant/${x.id}`,
    id: x.id,
    displayName: x.name,
    roleCount: x.roleCount ?? 0,
    userCount: x.userCount ?? 0,
    serviceCount: x.serviceCount ?? 0,
  }))
  type Row = NotOptional<typeof rows>[0]

  const loading = tenantQuery.loading
  const error = tenantQuery.error
  const refresh = () => tenantQuery.refetch()
  const avatar = DataTable.useAvatarColumn(IconTenant)

  const location = useLocation()

  useEffect(() => {
    refresh()
  }, [location.key])

  return (
    <Protected allowedRoles={['IDENTITYSERVER_SUPERUSER', 'IDENTITYSERVER_ORGANIZATION_ADMIN']}>
      <Wrapper title='Tenants'>
        <Box margin={2}>
          <Card>
            <CardHeader
              title={
                <Breadcrumbs separator={<IconBreadcrumbDivider fontSize='small' />} aria-label='breadcrumb'>
                  <Link color='textPrimary' component={RouterLink} to={`/organizations`}>
                    {organization?.name ?? `Organization`}
                  </Link>
                  <Link color='textPrimary' component={RouterLink} to={`/organization/${organizationId}/tenants`}>
                    Tenants
                  </Link>
                </Breadcrumbs>
              }
            />
            <CardErrorContent onRetry={refresh} error={error?.message} />
            <DataTable
              toolbar={
                <TableRouteButton
                  startIcon={<AddIcon />}
                  to={`/organization/${organizationId}/tenants/new`}
                  children='Add New Tenant'
                />
              }
              columns={[avatar, { field: 'displayName', headerName: `Name`, flex: 1 }]}
              data={rows ?? []}
              refresh={refresh}
              loading={!rows && loading}
              actions={[
                (row: Row) => ({
                  iconProps: {},
                  icon: `lock`,
                  tooltip: t('TOOLTIP_TENANT_ROLES_LIST', { count: row.roleCount }),
                  onClick: () => navigate(`/organization/${organizationId}/tenant/${row.id}/roles`),
                }),
                (row: Row) => ({
                  iconProps: {},
                  icon: `people`,
                  tooltip: t('TOOLTIP_TENANT_USERS_LIST', { count: row.userCount }),
                  onClick: () => navigate(`/organization/${organizationId}/tenant/${row.id}/users`),
                }),
                (row: Row) => ({
                  iconProps: {},
                  icon: `link`,
                  tooltip: t('TOOLTIP_TENANT_SERVICES_LIST', { count: row.serviceCount }),
                  onClick: () => navigate(`/organization/${organizationId}/tenant/${row.id}/services`),
                }),
                (row: Row) => ({
                  iconProps: { color: row.displayName === 'Mprise DevOps' ? 'disabled' : undefined },
                  icon: row.displayName === 'Mprise DevOps' ? '' : `delete`,
                  tooltip: row.displayName === 'Mprise DevOps' ? '' : t('LABEL_DELETE_TENANT'),
                  onClick: () =>
                    row.displayName === 'Mprise DevOps'
                      ? {}
                      : navigate(`/organization/${organizationId}/tenant/${row.id}/delete`),
                }),
              ]}
            />
          </Card>
        </Box>
      </Wrapper>
    </Protected>
  )
}
