import { Box, TextField } from '@material-ui/core'
import { MFlexBlock, MPortalDialog } from '@mprise/react-ui'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonAsyncClickHandler } from '../button/async'
import { useCreateRoleMutation } from '../graphql/generated'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'
import { Alerts } from '../shared/alerts'
import { MutationErrorMessage } from '../shared/apollo'

export const RoleNewDialog = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const { organizationId, tenantId } = useParams() as { organizationId: string; tenantId: string }
  const [name, setName] = useState(``)

  const [createRole, createRoleMutation] = useCreateRoleMutation()
  const handleCreate = useCallback<ButtonAsyncClickHandler>(
    async e => {
      e.preventDefault()

      const created = await createRole({ variables: { tenantId, name, permissions: [] } })

      if (created.data?.tenant?.createRole) {
        alerts.push(alertText.created(`Role`), `success`)
        navigate(`/organization/${organizationId}/tenant/${tenantId}/role/${created?.data?.tenant?.createRole?.id}`, {
          replace: true,
        })
      }
    },
    [name, tenantId],
  )

  const handleClose = () => {
    navigate(`/organization/${organizationId}/tenant/${tenantId}/roles`, { replace: true })
  }

  return (
    <>
      <MPortalDialog.Dialog minWidth='sm' open onClose={handleClose}>
        <SavingSwitchPanel mutations={[createRoleMutation]}>
          <MPortalDialog.Form onSubmit={handleCreate}>
            <MPortalDialog.Header onClose={handleClose}>
              <MFlexBlock justifyContent='flex-start' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {t(`New Role`)}
              </MFlexBlock>
            </MPortalDialog.Header>
            <MPortalDialog.Content>
              <Box paddingX={2}>
                <MutationErrorMessage mutation={createRoleMutation} />
                {/* <CardErrorContent error={tenant.error?.message} /> */}
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label='Name'
                  fullWidth
                  value={name}
                  onChange={e => setName(e.target.value)}
                  autoFocus
                  autoComplete='off'
                />
              </Box>
            </MPortalDialog.Content>
            <MPortalDialog.Footer>
              <MFlexBlock justifyContent='flex-end'>
                <MPortalDialog.SubmitButton>Create Role</MPortalDialog.SubmitButton>
              </MFlexBlock>
            </MPortalDialog.Footer>
          </MPortalDialog.Form>
        </SavingSwitchPanel>
      </MPortalDialog.Dialog>
    </>
  )
}
