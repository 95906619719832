import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Breadcrumbs, Card, CardHeader, Link } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { MAvatar } from '@mprise/react-ui'
import {
  useSpecificTenantQuery,
  useSpecificTenantRolesQuery,
  useSpecificTenantUsersQuery,
  useCosmosResourcesQuery,
  usePostgresResourcesQuery,
} from '../graphql/generated'
import { IconBreadcrumbDivider } from '../icons'
import { Wrapper } from '../layout/wrapper'
import { ActiveStatus } from '../shared/active-status'
import { CardErrorContent } from '../shared/card-error-content'
import Protected from '../shared/protected-route'
import { RouterLink } from '../shared/router-link'
import { defined } from '../shared/typescript'
import { useDebounceValue } from '../shared/use-debounced-value'
import { TableRouteButton } from '../table/button'
import { DataTable } from '../table/table'

const DEFAULT_PAGE_SIZE = 25

export const UserListRoute = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const params = useParams() as { organizationId: string; tenantId: string }
  const organizationId = params.organizationId
  const tenantId = params.tenantId

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounceValue(search, 300)

  const handleSearch = (text: string) => {
    setSearch(text)
  }

  const tenantQuery = useSpecificTenantQuery({ variables: { tenantId } })
  const tenantRolesQuery = useSpecificTenantRolesQuery({ variables: { tenantId } })
  const tenantUsersQuery = useSpecificTenantUsersQuery({
    variables: {
      filter: { tenant: { id: { eq: tenantId } } },
      skip: 0,
      take: DEFAULT_PAGE_SIZE,
      search: debouncedSearch,
    },
    fetchPolicy: 'cache-and-network',
  })

  const resourcesQuery = useCosmosResourcesQuery({ variables: { tenantId } })
  const microserviceResourcesQuery = usePostgresResourcesQuery({ variables: { tenantId } })
  const tenant = tenantQuery.data?.tenants?.[0]
  const users = tenantUsersQuery.data?.users?.items
  const roles = tenantRolesQuery.data?.tenants?.[0]?.roles
  const roleToName = new Map(roles?.map(x => [x?.id ?? `TODO`, x?.name ?? `TODO`]) ?? [])
  const resources = resourcesQuery.data?.cosmosResources
  const userRows = users?.filter(defined).map(x => ({
    url: `/organization/${organizationId}/tenant/${tenantId}/user/${x.id}`,
    id: x.id,
    subjectId: x.account?.subjectId,
    active: x.isActive ? `Active` : `Inactive`,
    isActive: x.isActive,
    roles: x.roles?.map(x => roleToName.get(x?.id ?? `TODO`) ?? x).join(`, `),
    email: x.account?.email,
    lastModified: new Date(x.updatedDate),
    resource: resources?.find(r => r?.externalUserId === x.account?.subjectId)?.name || '',
    microserviceResource:
      microserviceResourcesQuery.data?.postgresResources?.find(r => r?.externalUserId === x.account?.subjectId)?.name ||
      null,
  }))

  const totalCount = tenantUsersQuery.data?.users?.totalCount!

  const loading = tenantRolesQuery.loading || tenantUsersQuery.loading || resourcesQuery.loading
  const error = tenantRolesQuery.error || tenantUsersQuery.error || resourcesQuery.error
  const refresh = () => {
    tenantRolesQuery.refetch()
    tenantUsersQuery.refetch()
    resourcesQuery.refetch()
  }
  const avatar = DataTable.useAvatarColumn(MAvatar.Icon.Resource, `subjectId`)

  const hasNextPage = !loading && totalCount > userRows?.length!
  const handleNextPage = async (cursor: string) => {
    const nextCursor = Number(cursor)
    await tenantUsersQuery.fetchMore({
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) {
          return prev
        }
        return {
          __typename: prev.__typename,
          users: {
            ...prev.users,
            items: [...(prev.users?.items ?? []), ...(fetchMoreResult.users?.items ?? [])],
          },
        } as any
      },
      variables: {
        skip: nextCursor,
      },
    })
  }

  return (
    <Protected
      allowedRoles={['IDENTITYSERVER_SUPERUSER', 'IDENTITYSERVER_USER_ADMIN', 'IDENTITYSERVER_ORGANIZATION_ADMIN']}
    >
      <Wrapper title='Users'>
        <Box margin={2}>
          <Card>
            <CardHeader
              title={
                <Breadcrumbs separator={<IconBreadcrumbDivider fontSize='small' />} aria-label='breadcrumb'>
                  <Link color='textPrimary' component={RouterLink} to={`/organizations`}>
                    {tenant?.organization?.name ?? `Organization`}
                  </Link>
                  <Link color='textPrimary' component={RouterLink} to={`/organization/${organizationId}/tenants`}>
                    {tenant?.name ?? `Tenant`}
                  </Link>
                  <Link
                    color='textPrimary'
                    component={RouterLink}
                    to={`/organization/${organizationId}/tenant/${tenantId}/users`}
                  >
                    Users
                  </Link>
                </Breadcrumbs>
              }
            />
            <CardErrorContent onRetry={refresh} error={error?.message} />
            <DataTable
              hasNextPage={hasNextPage}
              onNextPage={handleNextPage}
              toolbar={
                <TableRouteButton
                  startIcon={<PersonAddIcon />}
                  to={`/organization/${organizationId}/tenant/${tenantId}/users/new`}
                  children='Add New User'
                />
              }
              columns={[
                avatar,
                { field: 'email', headerName: `Email`, flex: 2 },
                { field: 'roles', headerName: `Roles`, flex: 2 },
                { field: 'resource', headerName: `Resource`, flex: 2 },
                { field: 'microserviceResource', headerName: `Resource MS`, flex: 2 },

                {
                  field: 'isActive',
                  headerName: `Status`,
                  renderCell: row => <ActiveStatus label={row.row.active} active={row.row.isActive} />,
                  flex: 1,
                },
                {
                  field: `lastModified`,
                  headerName: `Last Modified`,
                  valueFormatter: e => (e.value as Date).toLocaleString(),
                  flex: 2,
                },
              ]}
              data={userRows ?? []}
              refresh={refresh}
              onSearch={handleSearch}
              loading={!userRows && loading}
              actions={[
                row => ({
                  iconProps: {},
                  icon: `delete`,
                  tooltip: t('UsersDeleteTooltip', { count: row.roles?.length }),
                  onClick: () => navigate(`/organization/${organizationId}/tenant/${tenantId}/user/${row.id}/delete`),
                }),
              ]}
            />
          </Card>
        </Box>
      </Wrapper>
    </Protected>
  )
}
