import { Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMe } from '../shared/useMe'
import { LockedLayout } from './layout'

export const LoginSelectPermissionsPage = () => {
  const me = useMe()
  const { tenantId } = useParams() as { tenantId: string }

  const [error, setError] = useState(``)
  const [, setSuccess] = useState(false)
  const [, setBusy] = useState(false)

  const onSubmit = async () => {
    setError(``)
    setBusy(true)
    try {
      const returnUrl = getReturnUrl(window.location.href)

      const res = await fetch(`/api/select-tenant`, {
        method: `POST`,
        redirect: `follow`,
        headers: {
          'content-type': `application/json`,
        },
        body: JSON.stringify({
          returnUrl,
          tenantId,
        }),
      })
      if (res.redirected) {
        window.location.href = res.url
      } else if (res.ok) {
        const model = await res.json()
        setSuccess(true)
        window.location.href = model.redirectUrl ?? window.location.origin
      } else {
        setError(`Unable to login: check your username and password or contact your administrator`)
      }
    } finally {
      setBusy(false)
    }
  }

  useEffect(() => {
    onSubmit()
  }, [tenantId])

  return (
    <LockedLayout>
      {error && (
        <Grid item xs={12}>
          <Alert severity='error'>{error}</Alert>
        </Grid>
      )}
      <LockedLayout.Header>Hi {me.email}</LockedLayout.Header>
      <LockedLayout.Loading>Activating permissions...</LockedLayout.Loading>
    </LockedLayout>
  )
}

const getReturnUrl = (url: string) => {
  const params = Array.from(new URL(url).searchParams.entries())
  for (const [k, v] of params) {
    if (k.toLowerCase() === `returnurl`) {
      return v
    }
  }
}
