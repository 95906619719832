import { Button, ButtonProps } from '@material-ui/core'
import React from 'react'
import { RouterLink } from '../shared/router-link'

export const TableRouteButton = ({ children, ...props }: ButtonProps<typeof RouterLink>) => {
  return (
    <Button variant='contained' color='primary' size='small' component={RouterLink} {...props}>
      {children}
    </Button>
  )
}
