import styled from '@emotion/styled'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { MText } from '@mprise/react-ui'
import jsonwebtoken from 'jsonwebtoken'
import React from 'react'
import { useResetPasswordResponseMutation } from '../graphql/generated'
import { LockedLayout } from './layout'
import { MutationErrorMessage } from '../shared/apollo'
import { RouterLink } from '../shared/router-link'

export const ResetPasswordPage = () => {
  const token = window.location.hash.replace(`#token=`, ``)
  const jwt = jsonwebtoken.decode(token)
  const email = typeof jwt === `object` ? jwt?.['email'] : null

  const [doResetPassword, resetPasswordMutation] = useResetPasswordResponseMutation()
  const onSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()

    const form = e.target as HTMLFormElement
    const token = form.elements.namedItem(`token`) as HTMLInputElement
    const password = form.elements.namedItem(`password`) as HTMLInputElement

    await doResetPassword({
      variables: {
        token: token.value,
        password: password.value,
      },
    }).catch(() => null)
  }

  if (resetPasswordMutation.loading) {
    return (
      <LockedLayout>
        <LockedLayout.Loading>Applying changes...</LockedLayout.Loading>
      </LockedLayout>
    )
  }

  if (resetPasswordMutation?.data?.resetPasswordResponse === false) {
    return (
      <LockedLayout>
        <LockedLayout.Header>Unable to reset your password</LockedLayout.Header>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MText block textVariant='content'>
              Request a new password
            </MText>
          </Grid>
          <Grid item xs={6}>
            <Button component={RouterLink} to='/login' variant='outlined' size='large' fullWidth>
              Back
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              component={RouterLink}
              to='/forgot-password'
              variant='contained'
              size='large'
              color='primary'
              fullWidth
            >
              Forgot Password
            </Button>
          </Grid>
        </Grid>
      </LockedLayout>
    )
  }

  if (resetPasswordMutation?.data?.resetPasswordResponse === true) {
    return (
      <LockedLayout>
        <LockedLayout.Header>Reset Password</LockedLayout.Header>
        <LockedLayout.Message>Success!</LockedLayout.Message>
        <LockedLayout.Actions right={{ type: `url`, url: `/login`, children: `Login` }} />
      </LockedLayout>
    )
  }

  return (
    <StyledForm method='POST' onSubmit={onSubmit}>
      <LockedLayout>
        <MutationErrorMessage mutation={resetPasswordMutation} />
        <LockedLayout.Header>Reset Password</LockedLayout.Header>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant='caption' align='center' style={{ fontSize: 16, textTransform: `uppercase` }}>
              Reset Password
            </Typography>
          </Grid>
          <input type='hidden' name='token' value={window.location.hash.replace(`#token=`, ``)} />
          <Grid item xs={12}>
            <TextField
              type='email'
              label='Email'
              name='email'
              placeholder='email@address.com'
              value={email}
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField label='Password' name='password' type='password' fullWidth InputLabelProps={{ shrink: true }} />
          </Grid>
          {resetPasswordMutation.error && (
            <Grid item xs={12}>
              <Alert severity='error'>{resetPasswordMutation.error.message}</Alert>
            </Grid>
          )}
        </Grid>
        <LockedLayout.Actions
          left={{ type: `url`, url: `/login`, children: `Back` }}
          right={{ type: `submit`, children: `Change Password` }}
        />
      </LockedLayout>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  display: contents;
`
