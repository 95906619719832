import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React from 'react'
import { ButtonAsync } from '../../button/async'
import { useRevokeServiceTokenMutation } from '../../graphql/generated'
import { SavingSwitchPanel } from '../../organization/saving-switch-panel'
import { MutationErrorMessage } from '../../shared/apollo'

export const ServiceTokenRevokeDialog = ({
  open,
  tenantId,
  tokenId,
  tokenName,
  onClose,
}: {
  open: boolean
  tokenName: string
  tenantId: string
  tokenId: string
  onClose: () => void
}) => {
  const [revokeServiceToken, revokeServiceTokenMutation] = useRevokeServiceTokenMutation()
  return (
    <Dialog open={open}>
      <SavingSwitchPanel mutations={[revokeServiceTokenMutation]}>
        <DialogTitle>Revoke Service Token</DialogTitle>
        <DialogContent>
          <MutationErrorMessage mutation={revokeServiceTokenMutation} />
          <DialogContentText>
            Are you sure you want to revoke the token: <code>{tokenName}</code>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonAsync
            onClick={async () => {
              onClose()
            }}
          >
            Cancel
          </ButtonAsync>
          <ButtonAsync
            onClick={async () => {
              const revoked = await revokeServiceToken({ variables: { tenantId, tokenId } })
              if (revoked.data?.tenant?.revokeServiceToken) {
                onClose()
              }
            }}
            variant='contained'
            color='secondary'
          >
            Revoke
          </ButtonAsync>
        </DialogActions>
      </SavingSwitchPanel>
    </Dialog>
  )
}
