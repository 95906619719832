import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { MAvatar, MColor } from '@mprise/react-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMeQuery } from '../graphql/generated'
import { IconCheck, IconListItemContinue } from '../icons'
import { QueryErrorMessage } from '../shared/apollo'
import { RouterLinkPlain } from '../shared/router-link'
import { useMe } from '../shared/useMe'
import { useLocalState } from '../utils'
import { LockedLayout } from './layout'

export const LoginPermissionsPage = () => {
  const { t } = useTranslation()
  const me = useMe()
  const meQuery = useMeQuery()
  const returnUrl = getReturnUrl(window.location.href)

  const available = me.permissions ?? []
  const [permissions, setPermissions] = useLocalState(available, [me.permissions?.join(`+`) ?? ``])

  if (meQuery.loading) {
    return (
      <LockedLayout>
        <LockedLayout.Header>Hi {me.email}</LockedLayout.Header>
        <LockedLayout.Loading>One moment ...</LockedLayout.Loading>
      </LockedLayout>
    )
  }

  return (
    <LockedLayout>
      <QueryErrorMessage query={meQuery} />
      <LockedLayout.Header>Choose permissions</LockedLayout.Header>
      <LockedLayout.Message>Hi {me.email}</LockedLayout.Message>
      <LockedLayout.Message>Disable permissions for testing purposes during this session</LockedLayout.Message>

      <List>
        {available?.map(m => (
          <ListItem button>
            <ListItemIcon>
              <MAvatar.Badge seed={m}>
                <IconCheck />
              </MAvatar.Badge>
            </ListItemIcon>
            <ListItemText primary={t(m)} />
            <ListItemIcon style={{ minWidth: 0 }}>
              <IconListItemContinue />
            </ListItemIcon>
          </ListItem>
        ))}
        <RouterLinkPlain
          to={`/login/select-permissions${
            returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : ``
          }#${permissions.join(`+`)}`}
        >
          <ListItem button>
            <ListItemIcon>
              <MAvatar.Badge color={MColor.dim}>
                <IconCheck />
              </MAvatar.Badge>
            </ListItemIcon>
            <ListItemText primary={t(`Continue`)} />
            <ListItemIcon style={{ minWidth: 0 }}>
              <IconListItemContinue />
            </ListItemIcon>
          </ListItem>
        </RouterLinkPlain>
      </List>
    </LockedLayout>
  )
}

const getReturnUrl = (url: string) => {
  const params = Array.from(new URL(url).searchParams.entries())
  for (const [k, v] of params) {
    if (k.toLowerCase() === `returnurl`) {
      return v
    }
  }
}
