import {
  Checkbox,
  FormControl,
  FormGroup,
  FormLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  PropTypes,
  TextField,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { defined } from './typescript'

export interface ListBoxItem {
  id: string
  name: string
  group?: string
  secondary?: string
}

export interface SearchBoxOptions {
  label: string
}

export const MultiSelectListBox = ({
  margin,
  suggestions,
  disabled,
  label,
  items,
  selected,
  search,
  onSearch,
  onChange,
}: {
  margin?: PropTypes.Margin
  suggestions: ListBoxItem[]
  disabled?: boolean
  label: string
  items: ListBoxItem[]
  selected: Array<string>
  search: string
  onSearch: (text: string) => void
  onChange: (selected: Array<string>) => void
}) => {
  const [all, setAll] = useState<Array<string>>([])
  useEffect(() => {
    setAll(Array.from(new Set([...all, ...selected])).filter(defined))
  }, [selected])

  const options = except(suggestions, selected, x => x.id)

  return (
    <>
      <FormControl component='fieldset' disabled={disabled} margin={margin} fullWidth>
        <FormLabel component='legend'>{label}</FormLabel>
        <FormGroup>
          <Autocomplete<ListBoxItem, false, true, true>
            options={options}
            filterSelectedOptions
            filterOptions={options => options}
            onInputChange={(_, v) => {
              onSearch(v)
            }}
            getOptionLabel={option => option?.name ?? `-`}
            groupBy={options.some(x => x.group) ? option => option.group ?? `` : undefined}
            value={null as any /* does not but should accept it */}
            inputValue={search}
            selectOnFocus
            onChange={(_, v) => {
              if (typeof v === `object`) {
                const included = Array.from(new Set([v?.id, ...selected])).filter(defined)
                onChange(included)
                onSearch(``)
              } else {
                throw Error(`expected a value of type object`)
              }
            }}
            fullWidth
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                placeholder='Add ...'
                autoComplete='off'
                autoCapitalize='off'
                autoCorrect='off'
              />
            )}
          />
          <List>
            {items.filter(item => all.includes(item.id)).length === 0 && (
              <ListItem dense>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText>Use the drop-down list to select {items.length > 1 ? `options` : `option`}</ListItemText>
              </ListItem>
            )}
            {grouped(
              items.filter(item => all.includes(item.id)),
              x => x.group ?? ``,
            )
              .sort((a, b) => a.groupName.localeCompare(b.groupName))
              .flatMap((item, idx) => (
                <React.Fragment key={idx}>
                  {item.groupName ? (
                    <ListSubheader
                      style={{
                        zIndex: 'auto',
                      }}
                    >
                      {item.groupName}
                    </ListSubheader>
                  ) : null}
                  {item.items.map((x, idx) => (
                    <Item key={idx} item={x} disabled={disabled} selected={selected} onChange={onChange} />
                  ))}
                </React.Fragment>
              ))}
          </List>
        </FormGroup>
      </FormControl>
    </>
  )
}

const grouped = <T extends unknown, K extends unknown>(items: T[], key: (item: T) => K) => {
  const table = new Map<K, T[]>()
  for (const item of items) {
    table.set(key(item), [...(table.get(key(item)) ?? []), item])
  }
  return Array.from(table.entries()).map(([x, y]) => ({ groupName: x, items: y }))
}

const Item = ({
  item,
  disabled,
  selected,
  onChange,
}: {
  item: ListBoxItem
  disabled?: boolean
  selected: Array<string>
  onChange: (selection: Array<string>) => void
}) => (
  <ListItem
    disableRipple
    button
    disabled={disabled}
    dense
    onClick={() => {
      const selection = new Set(selected)
      if (selection.has(item.id)) {
        selection.delete(item.id)
      } else {
        selection.add(item.id)
      }
      onChange(Array.from(selection))
    }}
  >
    <ListItemIcon>
      <Checkbox
        disableRipple
        tabIndex={-1}
        checked={selected.includes(item.id)}
        onChange={(_, checked) => {
          const selection = new Set(selected)
          if (checked) {
            selection.add(item.id)
          } else {
            selection.delete(item.id)
          }
          onChange(Array.from(selection))
        }}
      />
    </ListItemIcon>
    <ListItemText primary={item.name} secondary={item.secondary} />
  </ListItem>
)

const except = <T extends unknown, K extends unknown>(items: T[], remove: K[], key: (item: T) => K) => {
  let l = new Map(items.map(x => [key(x), x]))
  for (const i of remove) l.delete(i)
  return Array.from(l.values())
}
