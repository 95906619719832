import { Button } from '@material-ui/core'
import {
  MDivider,
  MFieldDate,
  MFieldInput,
  MFieldInteger,
  MFlexBlock,
  MJoinChildren,
  MPortalDialog,
} from '@mprise/react-ui'
import { Field, Form, Formik, FormikProps } from 'formik'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { useLicensesQuery, useModifyLicenseMutation } from '../graphql/generated'
import { LicenseTemplateEditDialog } from '../license-template/edit'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'
import { Alerts } from '../shared/alerts'
import { MutationErrorMessage } from '../shared/apollo'
import { MFieldConnector } from '../shared/mfield-adapter'
import { MFieldMultiSelect } from '../shared/mfield-multiselect'
import { defined } from '../shared/typescript'
import { ValidationIssues } from '../shared/validation-issues'
import { yupObject } from '../shared/yup-common-types'
import { useLocalState } from '../utils'
import { SelectTemplateDialog, SelectTemplateForm } from './select-template'

export interface ApplicationForm {
  title: string
  activeUntil: string | Date
  maxUsers: number
  permissions: Array<string>
}

export const LicenseEditDialog = () => {
  const { t } = useTranslation()

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const { licenseId } = useParams() as { licenseId: string }
  const navigate = useNavigate()
  const licenseQuery = useLicensesQuery()
  const license = licenseQuery.data?.tenants?.flatMap(x => x?.licenses ?? []).find(x => x?.id === licenseId)

  const permissionsPerApp = LicenseTemplateEditDialog.useLicensedPermissionsPerAppQuery()

  const [modify, modifyMutation] = useModifyLicenseMutation()
  const handleSave = async (form: ApplicationForm) => {
    const updated = await modify({
      variables: {
        title: form.title,
        activeUntil: form.activeUntil as any,
        allowPermissions: except(form.permissions, initialValues.permissions),
        denyPermissions: except(initialValues.permissions, form.permissions),
        licenseId: Number(licenseId),
        maxUsers: form.maxUsers,
      },
    })

    if (updated.data?.a && updated.data.b && updated.data.final) {
      alerts.push(alertText.updated(`License`), `success`)
      handleClose()
    }
  }

  const handleClose = () => {
    navigate(`/licenses`, { replace: true })
  }

  const schema = LicenseEditDialog.useSchema()

  const [initialValues] = useLocalState<ApplicationForm>(
    () => ({
      title: license?.title ?? ``,
      activeUntil: new Date(
        license?.activeUntil ?? `` /* HACK: remove date constructor when string is working again */,
      ),
      maxUsers: license?.maxUsers ?? 0,
      permissions: license?.permits?.map(x => x?.permissionId).filter(defined) ?? [],
    }),
    [license],
  )

  const [applyingTemplate, setApplyingTemplate] = useState(false)
  const handleApplyTemplate = (input: SelectTemplateForm | null) => {
    setApplyingTemplate(false)
    if (input?.template && formik.current) {
      if (input.replace) {
        formik.current.setFieldValue(`permissions`, input.template.permissions ?? [])
      } else {
        const combined = Array.from(new Set([...formik.current.values.permissions, ...input.template.permissions]))
        formik.current.setFieldValue(`permissions`, combined)
      }
    }
  }
  const handleSelectTemplate = () => {
    setApplyingTemplate(true)
  }

  const formik = useRef<FormikProps<ApplicationForm>>(null)

  return (
    <>
      <SelectTemplateDialog onClose={handleApplyTemplate} open={applyingTemplate} />
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={handleSave}
        innerRef={formik}
      >
        <MPortalDialog.Dialog open onClose={handleClose}>
          <SavingSwitchPanel mutations={[modifyMutation]}>
            <Form>
              <MPortalDialog.Header onClose={handleClose}>
                <MFlexBlock justifyContent='flex-start' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                  {t(`Edit License`)}
                </MFlexBlock>
              </MPortalDialog.Header>
              <MPortalDialog.Content>
                <ValidationIssues />
                <MJoinChildren divider={MDivider}>
                  <Field component={MFieldConnector} name='title' label={t(`License Title`)}>
                    <MFieldInput autoComplete='off' />
                  </Field>
                  <Field component={MFieldConnector} name='activeUntil' label={t(`Active Until`)}>
                    <MFieldDate />
                  </Field>
                  <Field component={MFieldConnector} name='maxUsers' label={t(`Maximum Users`)}>
                    <MFieldInteger min={0} />
                  </Field>
                  <Field component={MFieldConnector} name='permissions' label={t(`Permitted`)}>
                    <MFieldMultiSelect
                      items={permissionsPerApp.data ?? []}
                      suggestions={permissionsPerApp.data ?? []}
                      label='Permission'
                      onSearch={() => {}}
                      search=''
                    />
                  </Field>
                </MJoinChildren>
                <MutationErrorMessage mutation={modifyMutation} />
              </MPortalDialog.Content>
              <MPortalDialog.Footer>
                <MFlexBlock justifyContent='space-between'>
                  <Button color='primary' onClick={handleSelectTemplate}>
                    {t(`Apply license template`)}
                  </Button>
                  <MPortalDialog.SubmitButton>{t(`Edit License`)}</MPortalDialog.SubmitButton>
                </MFlexBlock>
              </MPortalDialog.Footer>
            </Form>
          </SavingSwitchPanel>
        </MPortalDialog.Dialog>
      </Formik>
    </>
  )
}

LicenseEditDialog.useSchema = () => {
  const { t } = useTranslation()
  const [schema] = useState(() => {
    return yupObject<ApplicationForm>({
      title: yup.string().label(t(`License Title`)) as any,
      activeUntil: yup.string().label(t(`Active Until`)) as any,
      maxUsers: yup.number().nullable().required().label(t(`Maximum Users`)),
      permissions: yup.array().label(t(`Permitted`)) as any,
    })
  })
  return schema
}

const except = <T extends unknown>(items: T[], remove: T[]) => {
  let l = new Set(items)
  for (const i of remove) l.delete(i)
  return Array.from(l.values())
}
