import { makeStyles } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type AlertType = 'error' | 'info' | 'success'

export type AlertEntry = { message: string; type: AlertType }

export interface AlertApi {
  push(message: string, type: AlertType): void
}

export const Alerts = ({ children }: { children: ReactNode }) => {
  const { context, current, close } = Alerts.useInternalState()
  return (
    <AlertContext.Provider value={context}>
      {current ? <AlertMessage value={current} onClick={close} /> : null}
      {children}
    </AlertContext.Provider>
  )
}

const AlertContext = React.createContext<AlertApi>({
  push() {
    throw Error(`missing <AlertContainer />`)
  },
})

Alerts.useTranslation = () => {
  const { t } = useTranslation()
  return {
    created(section: string) {
      return t('SuccessMessage', { section, message: 'created' })
    },
    deleted(section: string) {
      return t('SuccessMessage', { section, message: 'deleted' })
    },
    updated(section: string) {
      return t('SuccessMessage', { section, message: 'updated' })
    },
    duplicate(section: string) {
      return t('WarningMessage', { section })
    },
  }
}

Alerts.useAlert = () => {
  return useContext(AlertContext)
}

const AlertMessage = ({ value, onClick }: { value: AlertEntry; onClick: () => void }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <MuiAlert onClose={onClick} classes={{ root: value.type === `error` ? classes.warning : classes.success }}>
        {value.message}
      </MuiAlert>
    </div>
  )
}

Alerts.useInternalState = () => {
  const [stack, setStack] = useState<Array<AlertEntry>>([])

  // Context value, able to push alerts onto the stack
  const context = useMemo(
    () => ({
      push(message: string, type: AlertType) {
        setStack(prev => prev.concat({ message, type }))
      },
    }),
    [],
  )

  // Close first entry
  const close = () => {
    setStack(prev => prev.slice(1))
  }

  // Automatically close messages
  const current = stack[0]
  useEffect(() => {
    if (current) {
      const timer = setTimeout(() => close(), 2000)
      return () => clearTimeout(timer)
    }
  }, [current])

  return { current, context, close }
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 2000,
  },
  success: {
    backgroundColor: '#32CD32',
    borderRadius: '6px',
    color: 'white',
    fontSize: '1rem',
    margin: '10px',
    position: 'sticky',
    top: 0,
    right: 0,
    textAlign: 'center',
    '& .MuiAlert-icon': {
      color: 'white',
      fontSize: 22,
      opacity: 1,
    },
  },
  warning: {
    backgroundColor: '#E6980C',
    borderRadius: '6px',
    color: 'white',
    fontSize: '1rem',
    margin: '10px',
    position: 'sticky',
    top: 0,
    right: 0,
    textAlign: 'center',
    '& .MuiAlert-icon': {
      color: 'white',
      fontSize: 22,
      opacity: 1,
    },
  },
}))
