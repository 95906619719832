import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LockedLayout } from './layout'

export const AccesDenied = () => {
  const navigate = useNavigate()

  const handleSubmit = () => {
    navigate(`/login`)
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <LockedLayout>
        <LockedLayout.Row>
          <Typography variant='caption' align='center' style={{ fontSize: 16, textTransform: `uppercase` }}>
            Access denied
          </Typography>
        </LockedLayout.Row>

        <LockedLayout.Row>
          <Alert severity='error'>
            You have no access to the application, please contact your system administrator to grant you access.
          </Alert>
        </LockedLayout.Row>

        <LockedLayout.Actions right={{ type: `submit`, children: `To Login` }} />
      </LockedLayout>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  display: contents;
`
