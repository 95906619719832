import { Action } from '@reduxjs/toolkit'
import React, { Dispatch, useContext, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { useMe } from '../shared/useMe'
import { useAllTenantsQuery } from '../graphql/generated'
import { actions, initialState, reducer, TenantSelectState } from './select-state'

interface ContextValue {
  state: TenantSelectState
  dispatch: Dispatch<Action>
}

const TenantSelectContext = React.createContext<ContextValue>({
  state: initialState,
  dispatch(x) {
    return x
  },
})

export const useTenantSelect = () => useContext(TenantSelectContext)

export const TenantSelectProvider = ({ children }: { children: React.ReactNode }) => {
  const me = useMe()
  const [state, dispatch] = useReducer(reducer, initialState)
  const mapped = useMemo(() => ({ state, dispatch }), [state])

  const params = useParams() as { organizationId: string; tenantId?: string }
  const paramTenantId = params.tenantId
  const paramOrganizationId = params.organizationId

  const tenantQuery = useAllTenantsQuery()

  // Track tenant id at sartup
  useEffect(() => {
    const tenantId = paramTenantId ?? state.selected?.tenantId ?? me.tenantId
    const organizationId = paramOrganizationId ?? state.selected?.organizationId ?? me.organizationId
    if (organizationId && tenantId) {
      dispatch(actions.select({ organizationId, tenantId }))
    }
  }, [me])

  // Track tenant id from url
  useEffect(() => {
    const tenantId = paramTenantId
    const organizationId = paramOrganizationId
    if (organizationId && tenantId) {
      dispatch(actions.select({ organizationId, tenantId }))
    }
  }, [paramTenantId])

  useEffect(() => {
    if (tenantQuery.data) {
      dispatch(
        actions.load(
          tenantQuery.data?.tenants?.map(x => ({
            id: x!.id!,
            displayName: x!.name!,
            organizationId: x?.organization?.id,
          })) ?? [],
        ),
      )
    }
  }, [tenantQuery.data])

  return <TenantSelectContext.Provider value={mapped}>{children}</TenantSelectContext.Provider>
}
