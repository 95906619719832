import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { MAvatar, MColor, MFlexBlock, MText } from '@mprise/react-ui'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMeQuery } from '../graphql/generated'
import { IconListItemContinue, IconLogout, IconTenant } from '../icons'
import { QueryErrorMessage } from '../shared/apollo'
import { RouterLinkPlain } from '../shared/router-link'
import { useMe } from '../shared/useMe'
import { LockedLayout } from './layout'

export const LoginTenantListPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const me = useMe()
  const meQuery = useMeQuery()
  const memberOf = meQuery.data?.my?.account?.memberOf
  const returnUrl = getReturnUrl(window.location.href)

  const singleMemberOf = memberOf?.length === 1 && memberOf[0]
  useEffect(() => {
    if (singleMemberOf) {
      navigate(
        `/login/select-tenant/${singleMemberOf.id ?? ``}${
          returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : ``
        }`,
      )
    }
    // no tenants to choose from, the tenant has no valid license or the user is not active in the assigned tenants.
    if (meQuery.called && !meQuery.loading && memberOf?.length === 0) {
      navigate(`/access-denied`)
    }
  }, [singleMemberOf])

  if (meQuery.loading) {
    return (
      <LockedLayout>
        <LockedLayout.Header>Hi {me.email}</LockedLayout.Header>
        <LockedLayout.Loading>One moment ...</LockedLayout.Loading>
      </LockedLayout>
    )
  }

  if (meQuery.error?.graphQLErrors?.[0]?.extensions?.code === 'AUTH_NOT_AUTHORIZED') {
    return (
      <LockedLayout>
        <MFlexBlock vertical bgColor={MColor.medium} variant='rounded' padding={2}>
          <MText block textVariant='content bold'>
            {t(`ERROR`)}
          </MText>
          <MText block textVariant='small'>
            {t(`NOT_KNOWN_BY_ANY_TENANT`)}
          </MText>
        </MFlexBlock>
        <br />
        <LogoutButton />
      </LockedLayout>
    )
  }

  return (
    <LockedLayout>
      <QueryErrorMessage query={meQuery} />
      <LockedLayout.Header>Choose a tenant</LockedLayout.Header>
      <LockedLayout.Message>Hi {me.email}</LockedLayout.Message>
      <LockedLayout.Message>You are known by multiple tenants</LockedLayout.Message>

      <List>
        {memberOf?.map(m => (
          <RouterLinkPlain
            key={m?.id}
            to={`/login/select-tenant/${m?.id ?? ``}${returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : ``}`}
          >
            <ListItem button>
              <ListItemIcon>
                <MAvatar.Badge seed={m?.id ?? ``}>
                  <IconTenant />
                </MAvatar.Badge>
              </ListItemIcon>
              <ListItemText primary={m?.name} />
              <ListItemIcon style={{ minWidth: 0 }}>
                <IconListItemContinue />
              </ListItemIcon>
            </ListItem>
          </RouterLinkPlain>
        ))}
        <LogoutButton />
      </List>
    </LockedLayout>
  )
}

const getReturnUrl = (url: string) => {
  const params = Array.from(new URL(url).searchParams.entries())
  for (const [k, v] of params) {
    if (k.toLowerCase() === `returnurl`) {
      return v
    }
  }
}

const LogoutButton = () => {
  const { t } = useTranslation()
  return (
    <RouterLinkPlain to='/logout'>
      <ListItem button>
        <ListItemIcon>
          <MAvatar.Badge color={MColor.dim}>
            <IconLogout />
          </MAvatar.Badge>
        </ListItemIcon>
        <ListItemText primary={t(`Logout`)} />
        <ListItemIcon style={{ minWidth: 0 }}>
          <IconListItemContinue />
        </ListItemIcon>
      </ListItem>
    </RouterLinkPlain>
  )
}
