import {
  Box,
  Collapse,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
} from '@material-ui/core'
import BusinessIcon from '@material-ui/icons/Business'
import CloudIcon from '@material-ui/icons/Cloud'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import LinkIcon from '@material-ui/icons/Link'
import LockIcon from '@material-ui/icons/Lock'
import PeopleIcon from '@material-ui/icons/People'
import { MAvatar } from '@mprise/react-ui'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconLicense, IconOrganization } from '../icons'
import { RouterLink } from '../shared/router-link'
import { defined } from '../shared/typescript'
import { useMe } from '../shared/useMe'
import { useTenantSelect } from '../tenant/select'
import Logo from './logo-normal.svg'

export const AppDrawer = ({ open, onClose: handleCloseDrawer }: { open: boolean; onClose: () => void }) => {
  const me = useMe()
  const { t } = useTranslation()

  const classes = useStyles()

  const { state } = useTenantSelect()
  const selectedOrganizationId = state.selected?.organizationId ?? me.organizationId
  const selectedTenantId = state.selected?.tenantId ?? me.tenantId

  const drawer = (
    <>
      <Box flex={1}>
        <List disablePadding>
          <AppDrawer.LogoItem onClick={handleCloseDrawer} />
          <Divider />
          <AppDrawerUserItem onClick={handleCloseDrawer} />
          <Divider />
          <ListSubheader inset>{t('MENU_MANAGEMENT')}</ListSubheader>
          <AppDrawerMenuItem
            to={`/organization/${selectedOrganizationId}/tenant/${selectedTenantId}/apps-tiles`}
            onClick={handleCloseDrawer}
            icon={<CloudIcon />}
            name={t('MENU_APPSVIEW')}
          />
          {me.isOrganizationAdmin === false ? (
            <>
              {me.isRoleAdmin && (
                <AppDrawerMenuItem
                  to={`/organization/${selectedOrganizationId}/tenant/${selectedTenantId}/roles`}
                  onClick={handleCloseDrawer}
                  icon={<LockIcon />}
                  name={t('MENU_ROLES')}
                />
              )}
              {me.isUserAdmin && (
                <AppDrawerMenuItem
                  to={`/organization/${selectedOrganizationId}/tenant/${selectedTenantId}/users`}
                  onClick={handleCloseDrawer}
                  icon={<PeopleIcon />}
                  name={t('MENU_USERS')}
                />
              )}
              {me.isServiceAdmin && (
                <AppDrawerMenuItem
                  to={`/organization/${selectedOrganizationId}/tenant/${selectedTenantId}/services`}
                  onClick={handleCloseDrawer}
                  icon={<LinkIcon />}
                  name={t('MENU_SERVICES')}
                />
              )}
            </>
          ) : (
            <AppDrawerMenuItemGroup icon={<BusinessIcon />} name={t('MENU_ACCESS_CONTROL')}>
              <AppDrawerSubMenuItem
                to='/organizations'
                icon={<IconOrganization />}
                name={t('MENU_ORGANIZATIONS')}
                onClick={handleCloseDrawer}
              />
              <AppDrawerSubMenuItem
                to={`/organization/${selectedOrganizationId}/tenants`}
                icon={<GroupWorkIcon />}
                name={t('MENU_TENANTS')}
                onClick={handleCloseDrawer}
              />
              {me.isRoleAdmin && (
                <AppDrawerSubMenuItem
                  to={`/organization/${selectedOrganizationId}/tenant/${selectedTenantId}/roles`}
                  icon={<LockIcon />}
                  name={t('MENU_ROLES')}
                  onClick={handleCloseDrawer}
                />
              )}
              {me.isUserAdmin && (
                <AppDrawerSubMenuItem
                  to={`/organization/${selectedOrganizationId}/tenant/${selectedTenantId}/users`}
                  icon={<PeopleIcon />}
                  name={t('MENU_USERS')}
                  onClick={handleCloseDrawer}
                />
              )}
              {me.isServiceAdmin && (
                <AppDrawerSubMenuItem
                  to={`/organization/${selectedOrganizationId}/tenant/${selectedTenantId}/services`}
                  icon={<LinkIcon />}
                  name={t('MENU_SERVICES')}
                  onClick={handleCloseDrawer}
                />
              )}
            </AppDrawerMenuItemGroup>
          )}
          {me.isApplicationAdmin && (
            <ListItem button component={RouterLink} to='/applications' onClick={handleCloseDrawer}>
              <ListItemIcon>
                <CloudIcon />
              </ListItemIcon>
              <ListItemText>{t('MENU_APPLICATIONS')}</ListItemText>
            </ListItem>
          )}
          {me.isLicenseAdmin && (
            <ListItem button component={RouterLink} to='/licenses' onClick={handleCloseDrawer}>
              <ListItemIcon>
                <IconLicense />
              </ListItemIcon>
              <ListItemText>{t('MENU_LICENSES')}</ListItemText>
            </ListItem>
          )}
          {me.isLicenseAdmin && (
            <ListItem button component={RouterLink} to='/licensetemplates' onClick={handleCloseDrawer}>
              <ListItemIcon>
                <IconLicense />
              </ListItemIcon>
              <ListItemText>{t('MENU_LICENSE_TEMPLATES')}</ListItemText>
            </ListItem>
          )}
        </List>
      </Box>
      <Box>
        <List>
          <AppDrawerMenuItem
            to='/logout'
            icon={<ExitToAppOutlinedIcon className={classes.logoutIcon} />}
            name={t('Logout')}
            onClick={handleCloseDrawer}
          />
        </List>
      </Box>
    </>
  )

  return (
    <nav className={classes.drawer}>
      {/* @ts-expect-error // will be fixed when upgrading to MUI 5 */}
      <Hidden mdUp implementation='css'>
        <Drawer
          PaperProps={{ elevation: 1 }}
          variant='temporary'
          open={open}
          onClose={handleCloseDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      {/* @ts-expect-error // will be fixed when upgrading to MUI 5 */}
      <Hidden smDown implementation='css'>
        <Drawer
          PaperProps={{ elevation: 1 }}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant='permanent'
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  )
}
const drawerWidth = 280

AppDrawer.LogoItem = ({ onClick }: { onClick: React.MouseEventHandler }) => {
  return (
    <ListItem button component={RouterLink} to='/' disableRipple onClick={onClick}>
      <img
        alt='Mprise Agriware Logo'
        src={Logo}
        style={{ width: `100%`, maxHeight: 48, padding: 12, paddingTop: 4, paddingBottom: 4 }}
      />
    </ListItem>
  )
}

const AppDrawerUserItem = ({ onClick }: { onClick: React.MouseEventHandler }) => {
  const me = useMe()
  return (
    <ListItem button component={RouterLink} to='/me' onClick={onClick}>
      <ListItemIcon>
        <MAvatar.Badge seed={me.subjectId ?? ``}>
          <MAvatar.Icon.Resource />
        </MAvatar.Badge>
      </ListItemIcon>
      <ListItemText primary={me.email} secondary={me.tenantName ?? `-`} />
    </ListItem>
  )
}

const AppDrawerMenuItem = ({
  icon,
  name,
  to,
  onClick,
}: {
  icon: React.ReactNode
  name: React.ReactNode
  to: string
  onClick: React.MouseEventHandler
}) => {
  return (
    <ListItem button component={RouterLink} to={to} onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{name}</ListItemText>
    </ListItem>
  )
}

const AppDrawerSubMenuItem = ({
  icon,
  name,
  to,
  onClick,
}: {
  icon: React.ReactNode
  name: React.ReactNode
  to: string
  onClick: React.MouseEventHandler
}) => {
  const classes = useStyles()

  return (
    <ListItem className={classes.nested} button component={RouterLink} to={to} onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{name}</ListItemText>
    </ListItem>
  )
}

const AppDrawerMenuItemGroup = ({
  icon,
  name,
  children,
}: {
  icon: React.ReactNode
  name: React.ReactNode
  children: React.ReactNode
}) => {
  const [open, setOpen] = useState(false)
  const handleToggle = () => setOpen(x => !x)

  const hasChildren = React.Children.toArray(children).filter(defined).length > 0
  return hasChildren ? (
    <>
      <ListItem button onClick={handleToggle}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box flex={1}>{name}</Box>
            <Box>{open ? <ExpandLessIcon color='action' /> : <ExpandMoreIcon color='action' />}</Box>
          </Box>
        </ListItemText>
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List dense disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  ) : null
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  logoutIcon: {
    transform: `rotate(180deg)`,
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  nested: {
    paddingLeft: theme.spacing(5),
  },
}))
