import React, { useContext, useMemo } from 'react'
import { IdentityTenant, useMeQuery } from '../graphql/generated'
import { defined } from './typescript'

export const IdentityServerPermission = {
  IDENTITYSERVER_SUPERUSER: `IDENTITYSERVER_SUPERUSER` as 'IDENTITYSERVER_SUPERUSER',
  IDENTITYSERVER_APPLICATION_ADMIN: `IDENTITYSERVER_APPLICATION_ADMIN` as 'IDENTITYSERVER_APPLICATION_ADMIN',
  IDENTITYSERVER_ORGANIZATION_ADMIN: `IDENTITYSERVER_ORGANIZATION_ADMIN` as 'IDENTITYSERVER_ORGANIZATION_ADMIN',
  IDENTITYSERVER_LICENSE_ADMIN: `IDENTITYSERVER_LICENSE_ADMIN` as 'IDENTITYSERVER_LICENSE_ADMIN',
  IDENTITYSERVER_USER_ADMIN: `IDENTITYSERVER_USER_ADMIN` as 'IDENTITYSERVER_USER_ADMIN',
  IDENTITYSERVER_SERVICE_ADMIN: `IDENTITYSERVER_SERVICE_ADMIN` as 'IDENTITYSERVER_SERVICE_ADMIN',
  IDENTITYSERVER_ROLE_ADMIN: `IDENTITYSERVER_ROLE_ADMIN` as 'IDENTITYSERVER_ROLE_ADMIN',
}
export type IdentityServerPermission = keyof typeof IdentityServerPermission
export const IdentityServerPermissions = Object.values(IdentityServerPermission)
export const isIdentityServerPermission = (permission: string): permission is IdentityServerPermission =>
  IdentityServerPermissions.includes(permission as IdentityServerPermission)

export interface Me {
  authenticated: boolean
  activeUntil: Date | null
  displayName: string
  email: string
  isActive: boolean
  subjectId: string
  tenantUserId: string
  tenantId: string
  tenantName: string
  organizationId: string
  organizationName: string
  isApplicationAdmin: boolean
  isLicenseAdmin: boolean
  isServiceAdmin: boolean
  isOrganizationAdmin: boolean
  isRoleAdmin: boolean
  isUserAdmin: boolean
  permissions: IdentityServerPermission[]
  memberOf: Array<Pick<IdentityTenant, 'name' | 'id'>>
}

const emptyMe: Partial<Me> = {}
const MeContext = React.createContext(emptyMe)
export const MeProvider = ({ children }: { children: React.ReactNode }) => {
  const meQuery = useMeQuery({ fetchPolicy: `cache-first` })
  const state = useMemo((): Partial<Me> => {
    const authenticated = meQuery.loading ? undefined : Boolean(meQuery.data?.my?.account)
    const permissions =
      meQuery.data?.my?.user?.roles
        ?.flatMap(x => x?.permissions ?? [])
        .map(x => x?.id)
        .filter(defined)
        .filter(isIdentityServerPermission) ?? []

    if (!authenticated) {
      return { authenticated }
    }

    const displayName = meQuery.data?.my?.account?.email ?? ``
    const email = meQuery.data?.my?.account?.email ?? ``
    const subjectId = meQuery.data?.my?.account?.subjectId ?? ``
    const tenantUserId = meQuery.data?.my?.user?.id ?? ``
    const organizationId = meQuery.data?.my?.user?.tenant?.organization?.id ?? ``
    const organizationName = meQuery.data?.my?.user?.tenant?.organization?.name ?? ``
    const tenantId = meQuery.data?.my?.user?.tenant?.id ?? ``
    const tenantName = meQuery.data?.my?.user?.tenant?.name ?? ``
    const memberOf = meQuery.data?.my?.account?.memberOf?.filter(defined)
    const isSuperUser = permissions.includes(IdentityServerPermission.IDENTITYSERVER_SUPERUSER)
    const isApplicationAdmin =
      isSuperUser || permissions.includes(IdentityServerPermission.IDENTITYSERVER_APPLICATION_ADMIN)
    const isOrganizationAdmin =
      isSuperUser || permissions.includes(IdentityServerPermission.IDENTITYSERVER_ORGANIZATION_ADMIN)
    const isLicenseAdmin = isSuperUser || permissions.includes(IdentityServerPermission.IDENTITYSERVER_LICENSE_ADMIN)
    const isServiceAdmin = isSuperUser || permissions.includes(IdentityServerPermission.IDENTITYSERVER_SERVICE_ADMIN)
    const isRoleAdmin = isSuperUser || permissions.includes(IdentityServerPermission.IDENTITYSERVER_ROLE_ADMIN)
    const isUserAdmin = isSuperUser || permissions.includes(IdentityServerPermission.IDENTITYSERVER_USER_ADMIN)
    return {
      authenticated,
      displayName,
      email,
      subjectId,
      tenantUserId,
      tenantId,
      tenantName,
      organizationId,
      organizationName,
      isApplicationAdmin,
      isOrganizationAdmin,
      isLicenseAdmin,
      isServiceAdmin,
      isRoleAdmin,
      isUserAdmin,
      permissions,
      memberOf,
    }
  }, [meQuery.loading, meQuery.data])
  return <MeContext.Provider value={state ?? emptyMe}>{children}</MeContext.Provider>
}

export const useMe = () => useContext(MeContext)
