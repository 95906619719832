import { Box, Button } from '@material-ui/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Wrapper } from './layout/wrapper'

export const Signup = () => {
  const navigate = useNavigate()
  const handleLogout = () => {
    navigate(`/logout`)
  }
  const handleUserManagement = () => {
    navigate(`/`)
  }
  return (
    <Wrapper title='Login'>
      <Box margin={5}>
        Missing a subscription.
        <Box display='flex' marginTop={2} style={{ gap: `1rem` }}>
          <Button variant='outlined' onClick={handleLogout}>
            Logout
          </Button>
          <Button variant='contained' color='primary' onClick={handleUserManagement}>
            User Management
          </Button>
        </Box>
      </Box>
    </Wrapper>
  )
}
