import { Box, CircularProgress } from '@material-ui/core'
import { MText } from '@mprise/react-ui'
import React from 'react'

export const ProgressMessage = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box margin={4} marginX={{ sm: 8, lg: 24 }}>
      <Box display='flex' alignItems='center' flexDirection='row'>
        <Box flexShrink={0} padding={1}>
          <CircularProgress />
        </Box>
        <Box flexShrink={0} padding={1}>
          <MText block>{children}</MText>
        </Box>
      </Box>
    </Box>
  )
}
