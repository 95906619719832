import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { CircularProgress, Button } from '@material-ui/core'
import { MFlexBlock, MText, MColor, px } from '@mprise/react-ui'

export const NextPageView = ({ cursor, fetch }: { cursor: string | null; fetch: (cursor: string) => void }) => {
  const { t } = useTranslation()
  const __requested = useRef<string | null>(null)
  const [requested, setRequested] = useState<string | null>(null)
  const { ref, inView } = useInView()

  const handleFetch = () => {
    if (__requested.current === cursor) {
      // Already requested...
    } else {
      __requested.current = cursor
      setRequested(cursor)
      if (cursor) {
        fetch(cursor)
      }
    }
  }

  useEffect(() => {
    if (inView) {
      handleFetch()
    }
  }, [inView])

  return cursor ? (
    <div ref={ref}>
      {requested === cursor ? (
        <div style={{ minHeight: '100vh' }}>
          <MFlexBlock justifyContent='center' alignItems='center' padding={10} gap={5}>
            <MText block textColor={MColor.primary}>
              <CircularProgress color='inherit' size={px(48)} />
            </MText>
            <MText block textColor={MColor.primary}>
              {t('NOTIFICATION_NEXT_PAGE_LOADING')}
            </MText>
          </MFlexBlock>
        </div>
      ) : (
        <MFlexBlock justifyContent='center' alignItems='start' padding={10} gap={5}>
          <Button variant='outlined' color='primary' onClick={handleFetch}>
            {t('ACTION_LOAD_NEXT_PAGE')}
          </Button>
        </MFlexBlock>
      )}
    </div>
  ) : null
}
