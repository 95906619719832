import { MFieldInput } from '@mprise/react-ui'
import { Field, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  namedOperations,
  useCreateLicenseMutation,
  useLicenseTemplatesQuery,
  useMyOrganizationsQuery,
} from '../graphql/generated'
import { Alerts } from '../shared/alerts'
import { MFieldConnector } from '../shared/mfield-adapter'
import { MFieldAutoComplete } from '../shared/mfield-autocomplete'
import { FormikDialog } from '../shared/react-formik-dialog'
import { defined, Optional } from '../shared/typescript'
import { useLocalState } from '../utils'

export type LicenseNewForm = {
  tenant: Optional<{ id: string; name: string }>
  title: Optional<string>
  template: Optional<{ id: string; permissions: string[] }>
}

export const LicenseNewDialog = () => {
  const navigate = useNavigate()

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  // TODO: rather const tenantQuery1 = useMyTenantsQuery()
  const tenantQuery = useMyOrganizationsQuery()
  const tenants = tenantQuery.data?.organizations?.flatMap(
    organization =>
      organization?.tenants?.map(x => ({
        id: x?.id ?? `TODO`,
        name: x?.name ?? `-`,
        organization: organization.name ?? `-`,
      })) ?? [],
  )

  const [createLicense] = useCreateLicenseMutation({ refetchQueries: [namedOperations.Query.Licenses] })
  const handleSave = async (form: typeof initialValues) => {
    if (form.tenant) {
      const created = await createLicense({
        variables: {
          tenantId: form.tenant.id,
          title: form.title ?? ``,
          permissions: form.template?.permissions ?? [],
        },
      })

      if (created.data?.tenant?.createLicense) {
        alerts.push(alertText.created(`License`), `success`)
        navigate(`/license/${created?.data?.tenant?.createLicense?.id}`, { replace: true })
      }
    }
  }

  const handleClose = () => {
    navigate(`/licenses`, { replace: true })
  }
  const { t } = useTranslation()

  const templateQuery = useLicenseTemplatesQuery()
  const templates = templateQuery.data?.licenseTemplates?.filter(defined).map(x => ({
    id: x.id,
    name: x.name,
    permissions: x.permissions,
  }))

  const [initialValues] = useLocalState<LicenseNewForm>(
    {
      tenant: null,
      title: ``,
      template: null,
    },
    [],
  )

  return (
    <>
      <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSave}>
        <FormikDialog title='New License' submit='Create License' onClose={handleClose} open>
          <Field component={MFieldConnector} name='title' label={t(`Title`)}>
            <MFieldInput autoComplete='off' autoFocus />
          </Field>
          <Field component={MFieldConnector} name='tenant' label={t(`Tenant`)}>
            <MFieldAutoComplete items={tenants ?? []} label={item => item.name} group={item => item.organization} />
          </Field>
          <Field component={MFieldConnector} name='template' label={t(`Based on`)}>
            <MFieldAutoComplete items={templates ?? []} label={item => item.name} />
          </Field>
        </FormikDialog>
      </Formik>
    </>
  )
}
