import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { MAvatar, MColor } from '@mprise/react-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconListItemContinue, IconLogout, IconTenant } from '../icons'
import { RouterLinkPlain } from '../shared/router-link'
import { useMe } from '../shared/useMe'
import { LockedLayout } from './layout'

export const LoginMethodNotAllowedPage = () => {
  const me = useMe()
  const { t } = useTranslation()

  return (
    <LockedLayout>
      <LockedLayout.Message>Hi {me.email}</LockedLayout.Message>
      <LockedLayout.Message>
        Could not login to the selected tenant, because your current method of logging in was not allowed.
      </LockedLayout.Message>

      <List>
        <RouterLinkPlain to='/login/tenant'>
          <ListItem button>
            <ListItemIcon>
              <MAvatar.Badge color={MColor.primary}>
                <IconTenant />
              </MAvatar.Badge>
            </ListItemIcon>
            <ListItemText primary={t(`Try another tenant`)} />
            <ListItemIcon style={{ minWidth: 0 }}>
              <IconListItemContinue />
            </ListItemIcon>
          </ListItem>
        </RouterLinkPlain>
        <RouterLinkPlain to='/logout'>
          <ListItem button>
            <ListItemIcon>
              <MAvatar.Badge color={MColor.dim}>
                <IconLogout />
              </MAvatar.Badge>
            </ListItemIcon>
            <ListItemText primary={t(`Logout`)} />
            <ListItemIcon style={{ minWidth: 0 }}>
              <IconLogout />
            </ListItemIcon>
          </ListItem>
        </RouterLinkPlain>
      </List>
    </LockedLayout>
  )
}
