import { Box, Breadcrumbs, Card, CardHeader, Link } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useSpecificTenantQuery,
  useSpecificTenantRolesQuery,
  useSpecificTenantServicesQuery,
} from '../graphql/generated'
import { IconBreadcrumbDivider } from '../icons'
import { Wrapper } from '../layout/wrapper'
import { ActiveStatus } from '../shared/active-status'
import { CardErrorContent } from '../shared/card-error-content'
import Protected from '../shared/protected-route'
import { RouterLink } from '../shared/router-link'
import { defined, NotOptional } from '../shared/typescript'
import { TableRouteButton } from '../table/button'
import { DataTable } from '../table/table'

export const ServiceListRoute = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { organizationId, tenantId } = useParams() as { organizationId: string; tenantId: string }

  const tenantQuery = useSpecificTenantQuery({ variables: { tenantId } })
  const tenant = tenantQuery.data?.tenants?.[0]
  const servicesQuery = useSpecificTenantServicesQuery({ variables: { tenantId } })
  const services = servicesQuery.data?.tenants?.[0]?.services
  const roleQuery = useSpecificTenantRolesQuery({ variables: { tenantId } })
  const roles = roleQuery.data?.tenants?.flatMap(x => x?.roles ?? []) ?? []

  const roleToName = new Map(roles.map(x => [x?.id ?? `-`, x?.name ?? `-`]))
  const rows = services?.filter(defined).map(x => ({
    url: `/organization/${organizationId}/tenant/${tenantId}/service/${x.id}`,
    id: x.id ?? `-`,
    serviceId: x.id ?? `-`,
    servicePartialId: `\u2026${x.id?.substr(-6)}`,
    displayName: x.name,
    active: x.isActive ? `Active` : `Inactive`,
    isActive: x.isActive,
    roles: x.roles?.map(x => roleToName.get(x?.id ?? ``) ?? x).join(`, `),
  }))
  type Row = NotOptional<typeof rows>[0]

  const loading = servicesQuery.loading || roleQuery.loading
  const error = servicesQuery.error || roleQuery.error
  const refresh = () => {
    servicesQuery.refetch()
    roleQuery.refetch()
  }

  return (
    <Protected
      allowedRoles={['IDENTITYSERVER_SUPERUSER', 'IDENTITYSERVER_SERVICE_ADMIN', 'IDENTITYSERVER_ORGANIZATION_ADMIN']}
    >
      <Wrapper title='Services'>
        <Box margin={2}>
          <Card>
            <CardHeader
              title={
                <Breadcrumbs separator={<IconBreadcrumbDivider fontSize='small' />}>
                  <Link color='textPrimary' component={RouterLink} to={`/organizations`}>
                    {tenant?.organization?.name ?? `Organization`}
                  </Link>
                  <Link color='textPrimary' component={RouterLink} to={`/organization/${organizationId}/tenants`}>
                    {tenant?.name ?? `Tenant`}
                  </Link>
                  <Link
                    color='textPrimary'
                    component={RouterLink}
                    to={`/organization/${organizationId}/tenant/${tenantId}/services`}
                  >
                    Services
                  </Link>
                </Breadcrumbs>
              }
            />
            <CardErrorContent onRetry={refresh} error={error?.message} />
            <DataTable
              toolbar={
                <TableRouteButton
                  startIcon={<AddIcon />}
                  to={`/organization/${organizationId}/tenant/${tenantId}/services/new`}
                  children='Add New Service'
                />
              }
              columns={[
                { field: `servicePartialId`, headerName: `Service ID`, flex: 1 },
                { field: `displayName`, headerName: `Name`, flex: 2 },
                { field: 'roles', headerName: `Roles`, flex: 2 },
                {
                  field: 'isActive',
                  headerName: `Status`,
                  flex: 1,
                  renderCell: row => <ActiveStatus label={row.row.active} active={row.row.isActive} />,
                },
              ]}
              actions={[
                (row: Row) => ({
                  iconProps: {},
                  icon: `link`,
                  tooltip: t('ServiceListTokensAction'),
                  onClick: () =>
                    navigate(`/organization/${organizationId}/tenant/${tenantId}/service/${row.id}/tokens`),
                }),
              ]}
              data={rows ?? []}
              refresh={refresh}
              loading={loading}
            />
          </Card>
        </Box>
      </Wrapper>
    </Protected>
  )
}
