import { useRef, useState, useEffect } from 'react'

export const useDebounceValue = <T,>(value: T, timeout: number, debounceValue?: T) => {
  const prev = useRef(value) // used for deep compare

  const [state, setState] = useState(value)
  useEffect(() => {
    if (prev.current !== value) {
      // deep compare determined the value different

      if (debounceValue !== undefined) {
        setState(debounceValue)
      }
      const timer = setTimeout(() => {
        prev.current = value
        setState(value)
      }, timeout)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [value])
  return state
}
