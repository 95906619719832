import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { MFlexBlock, MPortalDialog } from '@mprise/react-ui'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonAsyncClickHandler } from '../../button/async'
import {
  CreateServiceTokenMutation,
  namedOperations,
  SpecificTenantServicesQuery,
  useCreateServiceTokenMutation,
} from '../../graphql/generated'
import { SavingSwitchPanel } from '../../organization/saving-switch-panel'
import { MutationErrorMessage } from '../../shared/apollo'
import { NotOptional } from '../../shared/typescript'
import { SetProperty, useLocalState } from '../../utils'

type ServiceTokenForm = NotOptional<
  NotOptional<
    NotOptional<
      NotOptional<NotOptional<NotOptional<SpecificTenantServicesQuery['tenants']>[0]>['services']>[0]
    >['tokens']
  >[0]
>

export const ServiceTokenNewDialog = () => {
  const { organizationId, tenantId, serviceId } = useParams() as {
    organizationId: string
    tenantId: string
    serviceId: string
  }
  const navigate = useNavigate()
  const [tokenCreated, setTokenCreated] = useState<IdentityServiceTokenCreated>()
  const handleSuccess = (token: IdentityServiceTokenCreated) => {
    setTokenCreated(token)
  }
  const handleClose = () => {
    navigate(`/organization/${organizationId}/tenant/${tenantId}/service/${serviceId}/tokens`, { replace: true })
  }
  return (
    <>
      <TokenCreateDialog
        open={!tokenCreated}
        organizationId={organizationId}
        tenantId={tenantId}
        serviceId={serviceId}
        onClose={handleClose}
        onSuccess={handleSuccess}
      />
      <TokenCreatedDialog
        open={!!tokenCreated}
        organizationId={organizationId}
        tenantId={tenantId}
        serviceId={serviceId}
        token={tokenCreated?.secret ?? `?`}
      />
    </>
  )
}

type IdentityServiceTokenCreated = NotOptional<CreateServiceTokenMutation['tenant']>['createServiceToken']
const TokenCreateDialog = ({
  organizationId,
  tenantId,
  serviceId,
  open,
  onClose,
  onSuccess,
}: {
  organizationId: string
  tenantId: string
  serviceId: string
  open: boolean
  onClose: () => void
  onSuccess: (created: IdentityServiceTokenCreated) => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [form, setForm] = useLocalState<ServiceTokenForm>(
    () => ({
      name: ``,
      active: true,
    }),
    [open],
  )

  const [createServiceToken, createServiceTokenMutation] = useCreateServiceTokenMutation({
    refetchQueries: [namedOperations.Query.SpecificTenantServices],
  })
  const save: ButtonAsyncClickHandler = async e => {
    e.preventDefault()
    // TODO
    const app = await createServiceToken({
      variables: {
        tenantId,
        serviceId,
        name: form.name,
      },
    })
    if (app.data?.tenant?.createServiceToken) {
      onSuccess(app.data.tenant.createServiceToken)
    }
  }

  const handleClose = () => {
    navigate(`/organization/${organizationId}/tenant/${tenantId}/service/${serviceId}/tokens`, { replace: true })
  }

  return (
    <MPortalDialog.Dialog minWidth='sm' open onClose={handleClose}>
      <SavingSwitchPanel mutations={[createServiceTokenMutation]}>
        <MPortalDialog.Form onSubmit={save}>
          <MPortalDialog.Header onClose={handleClose}>
            <MFlexBlock justifyContent='flex-start' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
              {t(`New Service Token`)}
            </MFlexBlock>
          </MPortalDialog.Header>
          <MPortalDialog.Content>
            <MutationErrorMessage mutation={createServiceTokenMutation} />
            <TextField
              fullWidth
              label='Name'
              value={form.name}
              onChange={e => setForm(SetProperty(`name`, e.target.value))}
              autoFocus
              autoComplete='off'
            />
          </MPortalDialog.Content>
          <MPortalDialog.Footer>
            <MFlexBlock justifyContent='flex-end'>
              <MPortalDialog.SubmitButton>Create Token</MPortalDialog.SubmitButton>
            </MFlexBlock>
          </MPortalDialog.Footer>
        </MPortalDialog.Form>
      </SavingSwitchPanel>
    </MPortalDialog.Dialog>
  )
}

const TokenCreatedDialog = ({
  open,
  organizationId,
  tenantId,
  serviceId,
  token,
}: {
  open: boolean
  organizationId: string
  tenantId: string
  serviceId: string
  token: string
}) => {
  const navigate = useNavigate()
  const classes = useStyles()
  return (
    <Dialog open={open}>
      <DialogTitle>Create Service Token</DialogTitle>
      <DialogContent>
        <DialogContentText>Use the following token at the client application:</DialogContentText>
        <Typography variant='caption'>Token</Typography>
        <code className={classes.code}>{token}</code>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            navigate(`/organization/${organizationId}/tenant/${tenantId}/service/${serviceId}/tokens`, {
              replace: true,
            })
          }}
          color='primary'
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  code: {
    display: `block`,
    userSelect: `all`,
    overflow: `auto`,
    backgroundColor: grey[100],
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
}))
