import styled from '@emotion/styled'
import { TextField } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import React from 'react'
import { useResetPasswordMutation } from '../graphql/generated'
import { LockedLayout } from './layout'

export const ForgotPasswordPage = () => {
  const [doResetPassword, resetPasswordMutation] = useResetPasswordMutation()
  const onSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()

    const form = e.target as HTMLFormElement
    const username = form.elements.namedItem(`username`) as HTMLInputElement

    await doResetPassword({
      variables: {
        email: username.value,
      },
    }).catch(() => null)
  }

  if (resetPasswordMutation.loading) {
    return (
      <LockedLayout>
        <LockedLayout.Header>Reset Password</LockedLayout.Header>
        <LockedLayout.Loading>Sending an email...</LockedLayout.Loading>
      </LockedLayout>
    )
  }

  if (resetPasswordMutation?.data?.resetPassword) {
    return (
      <LockedLayout>
        <LockedLayout.Header>Reset Password</LockedLayout.Header>
        <LockedLayout.Message>
          If your email is known, you should receive an email with instructions within 10 minutes.
        </LockedLayout.Message>
        <LockedLayout.Actions left={{ type: `url`, url: `/login`, children: `Back` }} />
      </LockedLayout>
    )
  }

  return (
    <StyledForm method='POST' onSubmit={onSubmit}>
      <LockedLayout>
        <LockedLayout.Header>Reset Password</LockedLayout.Header>
        <LockedLayout.Row>
          <TextField
            type='email'
            label='Email'
            name='username'
            placeholder='email@address.com'
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </LockedLayout.Row>
        {resetPasswordMutation.error && (
          <LockedLayout.Row>
            <Alert severity='error'>{resetPasswordMutation.error.message}</Alert>
          </LockedLayout.Row>
        )}
        <LockedLayout.Actions
          left={{ type: `url`, url: `/login`, children: `Back` }}
          right={{ type: `submit`, children: `Send Email` }}
        />
      </LockedLayout>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  display: contents;
`
