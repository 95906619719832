import { MFieldInput } from '@mprise/react-ui'
import { Field, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useEditOrganizationMutation,
  useKnownEmailsLazyQuery,
  useKnownEmailsQuery,
  useMyOrganizationsQuery,
} from '../graphql/generated'
import { Alerts } from '../shared/alerts'
import { MutationErrorMessage } from '../shared/apollo'
import { MFieldConnector } from '../shared/mfield-adapter'
import { MFieldMultiSelect } from '../shared/mfield-multiselect'
import Protected from '../shared/protected-route'
import { FormikDialog } from '../shared/react-formik-dialog'
import { defined } from '../shared/typescript'
import { useLocalState } from '../utils'
import { SavingSwitchPanel } from './saving-switch-panel'

export const OrganizationEditDialog = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const { organizationId } = useParams() as { organizationId: string }
  const organizationQuery = useMyOrganizationsQuery()
  const organization = organizationQuery.data?.organizations?.find(x => x?.id === organizationId)

  const [updateOrganization, updateOrganizationMutation] = useEditOrganizationMutation()
  const handleClose = () => {
    navigate(`/organizations`, { replace: true })
  }

  const handleSubmit = async (form: typeof initialValues) => {
    const updated = await updateOrganization({
      variables: { organizationId: organizationId, name: form.name ?? ``, owners: form.admins?.filter(defined) ?? [] },
    })

    if (updated.data?.organization?.update) {
      alerts.push(alertText.updated(`Organization`), `success`)
      handleClose()
    }
  }

  const emailSearch = OrganizationEditDialog.useEmailSearch()

  const [initialValues] = useLocalState(
    () => ({
      name: organization?.name ?? ``,
      admins: organization?.admins?.map(x => x?.email) ?? [],
    }),
    [organization],
  )

  return (
    <Protected allowedRoles={['IDENTITYSERVER_SUPERUSER', 'IDENTITYSERVER_ORGANIZATION_ADMIN']}>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
        <FormikDialog title={t(`Edit Organization`)} submit='Edit Organization' open onClose={handleClose}>
          <SavingSwitchPanel mutations={[updateOrganizationMutation]}>
            <MutationErrorMessage mutation={[updateOrganizationMutation, organizationQuery]} />
            <Field component={MFieldConnector} name='name' label={t(`Name`)}>
              <MFieldInput autoComplete='off' />
            </Field>
            <Field component={MFieldConnector} name='admins' label={t(`Owners`)}>
              <MFieldMultiSelect label='Users' items={emailSearch.all} {...emailSearch} />
            </Field>
          </SavingSwitchPanel>
        </FormikDialog>
      </Formik>
    </Protected>
  )
}

OrganizationEditDialog.useEmailSearch = () => {
  const all = useKnownEmailsQuery({ variables: { text: `` } })
  const [doQuery, query] = useKnownEmailsLazyQuery()
  const [search, setSearch] = useState(``)
  useEffect(() => {
    doQuery({ variables: { text: search } })
  }, [search])

  const matchesSearch = makeWildcard(search)
  const suggesions =
    query.data?.accounts
      ?.filter(defined)
      .map(x => ({ id: x.id ?? ``, name: x.email ?? `` }))
      .filter(x => matchesSearch(x.name)) ?? []

  return {
    all: Array.from(
      new Map(
        all.data?.accounts?.filter(defined).map(x => [x.id, { id: x.id ?? ``, name: x.email ?? `` }]) ?? [],
      ).values(),
    ),
    search,
    onSearch: setSearch,
    suggestions: suggesions,
  }
}

const makeWildcard = (text: string) => {
  const toWildcard = (text: string) =>
    text
      .replace(/[-[\]{}()+.,\\^$|#\s]/g, `\\$&`)
      .replace(/\?/g, `.`)
      .replace(/\*/g, `.*`)

  const toMatchSomewhere = (text: string) => `(?=.*${text})`

  const r = new RegExp(`^${text.split(/\s+/).map(toWildcard).map(toMatchSomewhere).join(``)}`, `i`)
  return (text: string) => !!text.match(r)
}
