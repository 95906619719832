import { LocalDate } from '@js-joda/core'
import { Box, CircularProgress } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MpriseTheme } from '@mprise/react-ui'
import { MpriseMuiThemeProvider } from './material-ui-theme'
import createJsJodaUtils from '@prinzdezibel/date-io-js-joda'
import React, { Suspense, useState } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { AuthorizedApolloClientProvider } from './apollo'
import { App } from './App'
import './index.css'
import './lib/translations/i18n'
import { Alerts } from './shared/alerts'
import { MeProvider } from './shared/useMe'
import { TenantSelectProvider } from './tenant/select'
import { TenantSelectDialog } from './tenant/select-dialog'

const launch = async () => {
  await documentReady()

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister()

      if (caches) {
        caches.keys().then(async names => {
          await Promise.all(names.map(name => caches.delete(name)))
        })
      }
    })
  }

  ReactDOM.render(
    <React.StrictMode>
      <Suspense
        fallback={
          <Box padding={2}>
            <CircularProgress />
          </Box>
        }
      >
        <DateWrappert>
          <AuthorizedApolloClientProvider>
            <MpriseMuiThemeProvider>
              <MpriseTheme />
              <BrowserRouter>
                <MeProvider>
                  <Alerts>
                    <TenantSelectProvider>
                      <TenantSelectDialog />
                      <App />
                    </TenantSelectProvider>
                  </Alerts>
                </MeProvider>
              </BrowserRouter>
            </MpriseMuiThemeProvider>
          </AuthorizedApolloClientProvider>
        </DateWrappert>
      </Suspense>
    </React.StrictMode>,
    document.getElementById('root'),
  )
}

const JsJodaLocale = require('@js-joda/locale_en-us').Locale
const DateWrappert = ({ children }: { children: React.ReactNode }) => {
  const [instance] = useState(() => createJsJodaUtils(LocalDate))
  return (
    <MuiPickersUtilsProvider utils={instance} locale={JsJodaLocale.US}>
      {children}
    </MuiPickersUtilsProvider>
  )
}

const documentReady = () => {
  return new Promise<void>(resolve => {
    if (/complete|interactive/.test(document.readyState)) {
      resolve()
    } else {
      window.addEventListener(`DOMContentLoaded`, () => resolve())
    }
  })
}

launch()
