import styled from '@emotion/styled'
import { Checkbox, FormControlLabel, Grid, Link, TextField, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LockedLayout } from './layout'
import { RouterLink } from '../shared/router-link'

export const Login = () => {
  const [error, setError] = useState(``)
  const [success, setSuccess] = useState(false)
  const [busy, setBusy] = useState(false)
  const navigate = useNavigate()
  const onSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()

    setError(``)
    setBusy(true)
    try {
      const form = e.target as HTMLFormElement
      const returnUrl = getReturnUrl(window.location.href)
      const username = form.elements.namedItem(`username`) as HTMLInputElement
      const password = form.elements.namedItem(`password`) as HTMLInputElement
      const rememberLogin = form.elements.namedItem(`rememberLogin`) as HTMLInputElement

      const res = await fetch(`/api/login`, {
        method: `POST`,
        redirect: `follow`,
        headers: {
          'content-type': `application/json`,
        },
        body: JSON.stringify({
          returnUrl,
          username: username.value,
          password: password.value,
          rememberLogin: rememberLogin.checked,
        }),
      })
      if (res.redirected) {
        window.location.href = res.url
      } else if (res.ok) {
        const model = await res.json()
        setSuccess(true)
        if (model.redirectUrl) {
          navigate(`/login/tenant?returnUrl=${encodeURIComponent(model.redirectUrl)}`)
        } else {
          navigate(`/login/tenant`)
        }
      } else {
        setError(`Unable to login: check your username and password or contact your administrator`)
      }
    } finally {
      setBusy(false)
    }
  }

  if (success) {
    return (
      <LockedLayout>
        <LockedLayout.Loading>Almost there...</LockedLayout.Loading>
      </LockedLayout>
    )
  }

  if (busy) {
    return (
      <LockedLayout>
        <LockedLayout.Loading>Logging in...</LockedLayout.Loading>
      </LockedLayout>
    )
  }

  return (
    <StyledForm method='POST' onSubmit={onSubmit}>
      <LockedLayout>
        <LockedLayout.Row>
          <Typography variant='caption' align='center' style={{ fontSize: 16, textTransform: `uppercase` }}>
            Log in to continue
          </Typography>
        </LockedLayout.Row>
        <LockedLayout.Row>
          <TextField
            type='email'
            label='Email'
            name='username'
            placeholder='email@address.com'
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </LockedLayout.Row>
        <LockedLayout.Row>
          <TextField label='Password' name='password' fullWidth type='password' InputLabelProps={{ shrink: true }} />
        </LockedLayout.Row>
        <LockedLayout.Row>
          <Grid container alignItems='center'>
            <FormControlLabel control={<Checkbox />} label='Remember me' name='rememberLogin' />
            <Link component={RouterLink} to='/forgot-password' style={{ marginLeft: `auto` }}>
              Forgot password
            </Link>
          </Grid>
        </LockedLayout.Row>
        {error && (
          <LockedLayout.Row>
            <Alert severity='error'>{error}</Alert>
          </LockedLayout.Row>
        )}
        <LockedLayout.Actions
          left={{ type: `redirect`, url: `/external/challenge?provider=aad`, children: `Azure AD` }}
          right={{ type: `submit`, children: `Log In` }}
        />

        <LockedLayout.Footer>
          Do you want to trial the Work Order App?{' '}
          <Link href='/' color='textPrimary' underline='always'>
            Sign Up!
          </Link>
        </LockedLayout.Footer>
      </LockedLayout>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  display: contents;
`

const getReturnUrl = (url: string) => {
  const params = Array.from(new URL(url).searchParams.entries())
  for (const [k, v] of params) {
    if (k.toLowerCase() === `returnurl`) {
      return v
    }
  }
}
