import { Box, CircularProgress, Dialog } from '@material-ui/core'
import React, { ReactNode, Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { gotoLogin } from './goto-login'
import { useMeQuery } from './graphql/generated'
import { AppBar } from './layout/bar'
import { AppDrawer } from './layout/drawer'
import { AccesDenied } from './locked/access-denied'
import { ForgotPasswordPage } from './locked/forgot-password'
import { Login } from './locked/login'
import { LoginInvitationPage } from './locked/login-confirm'
import { LoginMethodNotAllowedPage } from './locked/login-method-not-allowed'
import { LoginPermissionsPage } from './locked/login-permissions'
import { LoginSelectPermissionsPage } from './locked/login-select-permissions'
import { LoginSelectTenantPage } from './locked/login-select-tenant'
import { LoginTenantListPage } from './locked/login-tenant'
import { ResetPasswordPage } from './locked/reset-password'
import { Logout } from './logout'
import { RedirectToHome } from './redirect-to-home'
import { ProgressMessage } from './shared/progress-message'
import { useMe } from './shared/useMe'
import { Signup } from './signup'
import { OrganizationListRoute } from './organization/list'
import { Wrapper } from './layout/wrapper'
import { ApplicationListRoute } from './application/list'
import { LicenseTemplateListRoute } from './license-template/list'
import { LicenseListRoute } from './license/list'
import { AppsTiles } from './locked/apps-tiles'
import { RoleEditRoute } from './role/edit'
import { RoleListRoute } from './role/list'
import { ServiceListRoute } from './service/list'
import { ServiceTokenListRoute } from './service/token/list'
import { TenantListRoute } from './tenant/list'
import { UserListRoute } from './user/list'
import { ApplicationEditDialog } from './application/edit'
import { ApplicationNewDialog } from './application/new'
import { LicenseTemplateDeleteDialog } from './license-template/delete'
import { LicenseTemplateEditDialog } from './license-template/edit'
import { LicenseTemplateNewDialog } from './license-template/new'
import { LicenseConfigureDialog } from './license/configure'
import { LicenseEditDialog } from './license/edit'
import { LicenseNewDialog } from './license/new'
import { OrganizationDeleteDialog } from './organization/delete'
import { OrganizationEditDialog } from './organization/edit'
import { OrganizationNewDialog } from './organization/new'
import { RoleNewDialog } from './role/new'
import { ServiceEditDialog } from './service/edit'
import { ServiceNewDialog } from './service/new'
import { ServiceTokenNewDialog } from './service/token/new'
import { TenantDeleteDialog } from './tenant/delete'
import { TenantEditDialog } from './tenant/edit'
import { TenantNewDialog } from './tenant/new'
import { UserDeleteDialog } from './user/delete'
import { UserEditDialog } from './user/edit'
import { UserNewDialog } from './user/new'

export const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<RedirectToHome />} />
        <Route path='/access-denied' element={<AccesDenied />} />
        <Route path='/error' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPasswordPage />} />
        <Route path='/login' element={<Login />} />
        <Route path='/login/confirm' element={<LoginInvitationPage />} />
        <Route path='/login/method-not-allowed' element={<LoginMethodNotAllowedPage />} />
        <Route path='/login/permissions' element={<LoginPermissionsPage />} />
        <Route path='/login/select-permissions' element={<LoginSelectPermissionsPage />} />
        <Route path='/login/select-tenant/:tenantId' element={<LoginSelectTenantPage />} />
        <Route path='/login/tenant' element={<LoginTenantListPage />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/reset-password' element={<ResetPasswordPage />} />
        <Route path='/signup' element={<Signup />} />

        <Route path='/applications' element={<Protected page={<ApplicationListRoute />} />} />
        <Route path='/applications/new' element={<Protected page={<ApplicationListRoute />} />} />
        <Route path='/application/:applicationId' element={<Protected page={<ApplicationListRoute />} />} />

        <Route path='/licensetemplates' element={<Protected page={<LicenseTemplateListRoute />} />} />
        <Route path='/licensetemplates/new' element={<Protected page={<LicenseTemplateListRoute />} />} />
        <Route path='/licensetemplates/configure' element={<Protected page={<LicenseTemplateListRoute />} />} />
        <Route path='/licensetemplate/:templateId' element={<Protected page={<LicenseTemplateListRoute />} />} />
        <Route path='/licensetemplate/:templateId/delete' element={<Protected page={<LicenseTemplateListRoute />} />} />

        <Route path='/licenses' element={<Protected page={<LicenseListRoute />} />} />
        <Route path='/licenses/new' element={<Protected page={<LicenseListRoute />} />} />
        <Route path='/licenses/configure' element={<Protected page={<LicenseListRoute />} />} />
        <Route path='/license/:licenseId' element={<Protected page={<LicenseListRoute />} />} />

        <Route
          path='/organization/:organizationId/tenant/:tenantId/roles'
          element={<Protected page={<RoleListRoute />} />}
        />
        <Route
          path='/organization/:organizationId/tenant/:tenantId/roles/new'
          element={<Protected page={<RoleListRoute />} />}
        />

        <Route
          path='/organization/:organizationId/tenant/:tenantId/role/:roleId'
          element={<Protected page={<RoleEditRoute />} />}
        />

        <Route
          path='/organization/:organizationId/tenant/:tenantId/services'
          element={<Protected page={<ServiceListRoute />} />}
        />
        <Route
          path='/organization/:organizationId/tenant/:tenantId/services/new'
          element={<Protected page={<ServiceListRoute />} />}
        />
        <Route
          path='/organization/:organizationId/tenant/:tenantId/service/:serviceId'
          element={<Protected page={<ServiceListRoute />} />}
        />

        <Route
          path='/organization/:organizationId/tenant/:tenantId/service/:serviceId/tokens'
          element={<Protected page={<ServiceTokenListRoute />} />}
        />
        <Route
          path='/organization/:organizationId/tenant/:tenantId/service/:serviceId/tokens/new'
          element={<Protected page={<ServiceTokenListRoute />} />}
        />

        <Route
          path='/organization/:organizationId/tenant/:tenantId/users'
          element={<Protected page={<UserListRoute />} />}
        />
        <Route
          path='/organization/:organizationId/tenant/:tenantId/users/new'
          element={<Protected page={<UserListRoute />} />}
        />
        <Route
          path='/organization/:organizationId/tenant/:tenantId/user/:userId'
          element={<Protected page={<UserListRoute />} />}
        />
        <Route
          path='/organization/:organizationId/tenant/:tenantId/user/:userId/delete'
          element={<Protected page={<UserListRoute />} />}
        />

        <Route
          path='/organization/:organizationId/tenant/:tenantId/apps-tiles'
          element={<Protected page={<AppsTiles />} />}
        />

        <Route path='/organization/:organizationId/tenants' element={<Protected page={<TenantListRoute />} />} />
        <Route path='/organization/:organizationId/tenants/new' element={<Protected page={<TenantListRoute />} />} />
        <Route
          path='/organization/:organizationId/tenant/:tenantId'
          element={<Protected page={<TenantListRoute />} />}
        />
        <Route
          path='/organization/:organizationId/tenant/:tenantId/delete'
          element={<Protected page={<TenantListRoute />} />}
        />

        <Route path='/organizations' element={<Protected page={<OrganizationListRoute />} />} />
        <Route path='/organizations/new' element={<Protected page={<OrganizationListRoute />} />} />
        <Route path='/organization/:organizationId' element={<Protected page={<OrganizationListRoute />} />} />
        <Route path='/organization/:organizationId/delete' element={<Protected page={<OrganizationListRoute />} />} />

        <Route path='/me' element={<RedirectToMe />} />

        <Route element={<RedirectToHome />} />
      </Routes>
      {/* Second 'Routes' element with identical paths makes it so the original pages are still rendered behind the open dialogs. */}
      <Routes>
        <Route path='/application/:applicationId' element={<ApplicationEditDialog />} />
        <Route path='/applications/new' element={<ApplicationNewDialog />} />
        <Route path='/license/:licenseId' element={<LicenseEditDialog />} />
        <Route path='/licenses/new' element={<LicenseNewDialog />} />
        <Route path='/licenses/configure' element={<LicenseConfigureDialog />} />
        <Route path='/licensetemplate/:templateId' element={<LicenseTemplateEditDialog />} />
        <Route path='/licensetemplate/:templateId/delete' element={<LicenseTemplateDeleteDialog />} />
        <Route path='/licensetemplates/new' element={<LicenseTemplateNewDialog />} />
        <Route path='/licensetemplates/configure' element={<LicenseConfigureDialog />} />
        <Route path='/organizations/new' element={<OrganizationNewDialog />} />
        <Route path='/organization/:organizationId' element={<OrganizationEditDialog />} />
        <Route path='/organization/:organizationId/delete' element={<OrganizationDeleteDialog />} />
        <Route path='/organization/:organizationId/tenant/:tenantId' element={<TenantEditDialog />} />
        <Route path='/organization/:organizationId/tenant/:tenantId/delete' element={<TenantDeleteDialog />} />
        <Route path='/organization/:organizationId/tenant/:tenantId/roles/new' element={<RoleNewDialog />} />
        <Route path='/organization/:organizationId/tenant/:tenantId/user/:userId' element={<UserEditDialog />} />
        <Route
          path='/organization/:organizationId/tenant/:tenantId/user/:userId/delete'
          element={<UserDeleteDialog />}
        />
        <Route path='/organization/:organizationId/tenant/:tenantId/users/new' element={<UserNewDialog />} />
        <Route
          path='/organization/:organizationId/tenant/:tenantId/service/:serviceId'
          element={<ServiceEditDialog />}
        />
        <Route path='/organization/:organizationId/tenant/:tenantId/services/new' element={<ServiceNewDialog />} />
        <Route
          path='/organization/:organizationId/tenant/:tenantId/service/:serviceId/tokens/new'
          element={<ServiceTokenNewDialog />}
        />
        <Route path='/organization/:organizationId/tenants/new' element={<TenantNewDialog />} />
      </Routes>
    </>
  )
}

const Protected = ({ page }: { page: ReactNode }) => {
  const [drawer, setDrawer] = useState(false)
  const handleCloseDrawer = () => setDrawer(false)
  const handleToggleDrawer = () => setDrawer(x => !x)

  const meQuery = useMeQuery({ pollInterval: 300_000 })

  if (meQuery.loading) {
    return (
      <Dialog open={true} hideBackdrop>
        <ProgressMessage>Authenticating ...</ProgressMessage>
      </Dialog>
    )
  } else {
    const isAuthenticated = Boolean(meQuery.data?.my?.account)
    if (!isAuthenticated) {
      return <Navigate to='/login' replace />
    } else {
      return (
        <Box display='flex'>
          <AppDrawer open={drawer} onClose={handleCloseDrawer} />
          <Box flexGrow={1} position='relative'>
            <AppBar title={meQuery.data?.my?.user?.tenant?.name ?? `-`} onToggleDrawer={handleToggleDrawer} />
            <Suspense
              fallback={
                <Box padding={2}>
                  <CircularProgress />
                </Box>
              }
            >
              {page}
            </Suspense>
          </Box>
        </Box>
      )
    }
  }
}

const RedirectToMe = () => {
  const me = useMe()
  useEffect(() => {
    if (me.authenticated === false) {
      gotoLogin()
    }
  }, [me.authenticated])

  if (me.subjectId) {
    return <Navigate to={`/organization/${me.organizationId}/tenant/${me.tenantId}/user/${me.tenantUserId}`} replace />
  } else {
    return (
      <Wrapper title='User Details'>
        <ProgressMessage>One moment...</ProgressMessage>
      </Wrapper>
    )
  }
}
