import { Dispatch, SetStateAction, useState, useEffect, DependencyList } from 'react'

// export const useLocalState = <T extends unknown>(source: T): [T, Dispatch<SetStateAction<T>>] => {
//     const [state, setState] = useState(() => source);
//     useEffect(() => {
//         setState(() => source);
//     }, [source]);
//     return [state, setState];
// };

export const useLocalState = <T extends unknown>(
  source: T | (() => T),
  deps: DependencyList = [source],
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState(source)
  useEffect(() => setState(source), deps)
  return [state, setState]
}

export const SetProperty =
  <T extends {}, K extends keyof T>(key: K, value: T[K]) =>
  (obj: T) => ({ ...obj, [key]: value })

export function unique<T extends unknown, K extends unknown>(array: ReadonlyArray<T>, key?: (item: T) => K) {
  if (key) {
    const reversed = array.slice().reverse() // first unique one appears in output
    const combined = new Map(reversed.map(x => [key(x), x]))
    const output = Array.from(combined.values())
    return output
  } else {
    return Array.from(new Set(array).values())
  }
}
