import { Box, Breadcrumbs, Card, CardHeader, Link } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import { useParams } from 'react-router-dom'
import { TenantRolePermission, useSpecificTenantQuery, useSpecificTenantRolesQuery } from '../graphql/generated'
import { IconBreadcrumbDivider } from '../icons'
import { Wrapper } from '../layout/wrapper'
import { CardErrorContent } from '../shared/card-error-content'
import Protected from '../shared/protected-route'
import { RouterLink } from '../shared/router-link'
import { defined, Optional } from '../shared/typescript'
import { TableRouteButton } from '../table/button'
import { DataTable } from '../table/table'

export const RoleListRoute = () => {
  const { organizationId, tenantId } = useParams() as { organizationId: string; tenantId: string }

  const tenantQuery = useSpecificTenantQuery({ variables: { tenantId } })
  const tenant = tenantQuery.data?.tenants?.[0]
  const roleQuery = useSpecificTenantRolesQuery({ variables: { tenantId } })
  const roles = roleQuery.data?.tenants?.flatMap(x => x?.roles ?? []) ?? []

  const error = tenantQuery.error || roleQuery.error
  const loading = tenantQuery.loading || roleQuery.loading
  const refresh = () => {
    tenantQuery.refetch()
    roleQuery.refetch()
  }

  const rows = roles?.filter(defined).map(({ id, name, permissions, users, updatedDate }) => ({
    url: `/organization/${organizationId}/tenant/${tenantId}/role/${id}`,
    id,
    name,
    permissions: permissions?.length ?? 0,
    users: users?.length ?? 0,
    lastModified: new Date(updatedDate),
    seats: renderSeats(permissions),
  }))

  return (
    <Protected
      allowedRoles={['IDENTITYSERVER_SUPERUSER', 'IDENTITYSERVER_ROLE_ADMIN', 'IDENTITYSERVER_ORGANIZATION_ADMIN']}
    >
      <Wrapper title='Roles'>
        <Box margin={2}>
          <Card>
            <CardHeader
              title={
                <Breadcrumbs separator={<IconBreadcrumbDivider fontSize='small' />}>
                  <Link color='textPrimary' component={RouterLink} to={`/organizations`}>
                    {tenant?.organization?.name ?? `Organization`}
                  </Link>
                  <Link color='textPrimary' component={RouterLink} to={`/organization/${organizationId}/tenants`}>
                    {tenant?.name ?? `Tenant`}
                  </Link>
                  <Link
                    color='textPrimary'
                    component={RouterLink}
                    to={`/organization/${organizationId}/tenant/${tenantId}/roles`}
                  >
                    Roles
                  </Link>
                </Breadcrumbs>
              }
            />
            <CardErrorContent onRetry={refresh} error={error?.message} />
            <DataTable
              toolbar={
                <TableRouteButton
                  startIcon={<AddIcon />}
                  to={`/organization/${organizationId}/tenant/${tenantId}/roles/new`}
                  children='Add New Role'
                />
              }
              columns={[
                { field: 'name', headerName: `Role`, flex: 2 },
                { field: 'permissions', headerName: `Permissions`, flex: 1 },
                { field: 'users', headerName: `Users`, flex: 1 },
                { field: 'seats', headerName: `Utilized Seats`, flex: 1 },
                {
                  field: `lastModified`,
                  headerName: `Last Modified`,
                  flex: 2,
                  valueFormatter: e => (e.value as Date).toLocaleString(),
                },
              ]}
              data={rows ?? []}
              refresh={refresh}
              loading={loading}
            />
          </Card>
        </Box>
      </Wrapper>
    </Protected>
  )
}

const renderSeats = (
  permissions: Optional<Array<Optional<Pick<TenantRolePermission, 'licenseUsed' | 'licenseTotal'>>>>,
) => {
  const used = maxUsed(permissions)
  const total = minTotal(permissions)
  if (used === null && total === null) {
    return `\u2014`
  } else {
    return `${used ?? `-`} / ${total ?? `-`}`
  }
}

const maxUsed = (input: Optional<Array<Optional<Pick<TenantRolePermission, 'licenseUsed'>>>>) =>
  input?.reduce<null | number>((s, n) => max(s, n?.licenseUsed ?? null), null) ?? null

const minTotal = (input: Optional<Array<Optional<Pick<TenantRolePermission, 'licenseTotal'>>>>) =>
  input?.reduce<null | number>((s, n) => min(s, n?.licenseTotal ?? null), null) ?? null

const min = (a: number | null, b: number | null) => {
  if (a === null && b === null) {
    return null
  } else if (a === null || b === null) {
    return a ?? b
  } else {
    return Math.min(a, b)
  }
}

const max = (a: number | null, b: number | null) => {
  if (a === null && b === null) {
    return null
  } else if (a === null || b === null) {
    return a ?? b
  } else {
    return Math.max(a, b)
  }
}
