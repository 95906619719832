import { Box, Grid, TextField } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MFlexBlock, MPortalDialog } from '@mprise/react-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ButtonAsyncClickHandler } from '../button/async'
import { GlobalApplicationsQuery, namedOperations, useCreateApplicationMutation } from '../graphql/generated'
import { Alerts } from '../shared/alerts'
import { defined, NotOptional } from '../shared/typescript'
import { SetProperty, useLocalState } from '../utils'

type ApplicationForm = NotOptional<NotOptional<GlobalApplicationsQuery['applications']>[0]>

export const ApplicationNewDialog = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [form, setForm] = useLocalState<ApplicationForm>(
    {
      name: ``,
      isActive: true,
    },
    [],
  )

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const [createApplication] = useCreateApplicationMutation({
    refetchQueries: [namedOperations.Query.MyApplications, namedOperations.Query.GlobalApplications],
  })

  const save: ButtonAsyncClickHandler = async e => {
    e.preventDefault()

    const created = await createApplication({
      variables: {
        name: form.name ?? `-`,
        publicUrl: form.publicUrl,
        logoUrl: form.logoUrl,
        activeUntil: form.activeUntil,
        redirectUrls: form.redirectUris?.filter(defined) ?? [],
        postLogoutRedirectUrls: form.postLogoutRedirectUris?.filter(defined) ?? [],
        allowedCorsOrigins: form.allowedCorsOrigins?.filter(defined) ?? [],
        allowedScopes: form.allowedScopes?.filter(defined) ?? [],
        permissions: form.permissions?.filter(defined) ?? [],
      },
    })

    if (created.data?.createApplication) {
      alerts.push(alertText.created(`Application`), `success`)
      handleClose()
    }
  }

  const handleClose = () => {
    navigate(`/applications`, { replace: true })
  }

  return (
    <>
      <MPortalDialog.Dialog minWidth='sm' open onClose={handleClose}>
        <MPortalDialog.Form onSubmit={save}>
          <MPortalDialog.Header onClose={handleClose}>
            <MFlexBlock justifyContent='flex-start' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
              {t(`New Application`)}
            </MFlexBlock>
          </MPortalDialog.Header>
          <MPortalDialog.Content>
            <Box paddingX={2}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label='Client ID'
                    required
                    value={form.id}
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <KeyboardDatePicker
                    fullWidth
                    label='Valid Period'
                    placeholder='Application Active Until'
                    format='yyyy-MM-dd'
                    value={form.activeUntil ?? null}
                    onChange={v => setForm(SetProperty(`activeUntil`, v as any))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth InputLabelProps={{ shrink: true }} label='Secret' value={form.secret} disabled />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label='Name'
                    value={form.name}
                    onChange={e => setForm(SetProperty(`name`, e.target.value))}
                    autoFocus
                    autoComplete='off'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label='Public URL'
                    value={form.publicUrl}
                    onChange={e => setForm(SetProperty(`publicUrl`, e.target.value))}
                    fullWidth
                    multiline
                    autoComplete='off'
                    autoCapitalize='off'
                    autoCorrect='off'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label='Logo URL'
                    value={form.logoUrl}
                    onChange={e => setForm(SetProperty(`logoUrl`, e.target.value))}
                    fullWidth
                    multiline
                    autoComplete='off'
                    autoCapitalize='off'
                    autoCorrect='off'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label='Redirect uris'
                    value={form.redirectUris?.join(`\n`)}
                    onChange={e => setForm(SetProperty(`redirectUris`, e.target.value.split(/\r?\n/g)))}
                    fullWidth
                    multiline
                    autoComplete='off'
                    autoCapitalize='off'
                    autoCorrect='off'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label='Post logout redirect uris'
                    value={form.postLogoutRedirectUris?.join(`\n`)}
                    onChange={e => setForm(SetProperty(`postLogoutRedirectUris`, e.target.value.split(/\r?\n/g)))}
                    fullWidth
                    multiline
                    autoComplete='off'
                    autoCapitalize='off'
                    autoCorrect='off'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label='Allowed cors origins'
                    value={form.allowedCorsOrigins?.join(`\n`)}
                    onChange={e => setForm(SetProperty(`allowedCorsOrigins`, e.target.value.split(/\r?\n/g)))}
                    fullWidth
                    multiline
                    autoComplete='off'
                    autoCapitalize='off'
                    autoCorrect='off'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label='Allowed scopes'
                    value={form.allowedScopes?.join(`\n`)}
                    onChange={e => setForm(SetProperty(`allowedScopes`, e.target.value.split(/\r?\n/g)))}
                    fullWidth
                    multiline
                    autoComplete='off'
                    autoCapitalize='off'
                    autoCorrect='off'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label='Permissions'
                    value={form.permissions?.join(`\n`)}
                    onChange={e => setForm(SetProperty(`permissions`, e.target.value.split(/\r?\n/g)))}
                    fullWidth
                    multiline
                    autoComplete='off'
                    autoCapitalize='off'
                    autoCorrect='off'
                  />
                </Grid>
              </Grid>
            </Box>
          </MPortalDialog.Content>
          <MPortalDialog.Footer>
            <MFlexBlock justifyContent='flex-end'>
              <MPortalDialog.SubmitButton>Create Application</MPortalDialog.SubmitButton>
            </MFlexBlock>
          </MPortalDialog.Footer>
        </MPortalDialog.Form>
      </MPortalDialog.Dialog>
    </>
  )
}
