import { Box, Card, CardHeader } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import { Wrapper } from '../layout/wrapper'
import { CardErrorContent } from '../shared/card-error-content'
import { TableRouteButton } from '../table/button'
import { DataTable } from '../table/table'
import { ActiveStatus } from '../shared/active-status'
import { useGlobalApplicationsQuery } from '../graphql/generated'
import { defined } from '../shared/typescript'
import Protected from '../shared/protected-route'

export const ApplicationListRoute = () => {
  const applicationQuery = useGlobalApplicationsQuery()
  const rows = applicationQuery.data?.applications?.filter(defined).map(x => ({
    url: `/application/${x.id}`,
    id: x.id,
    clientId: x.id,
    name: x.name,
    active: x.isActive ? `Active` : `Inactive`,
    isActive: x.isActive,
    allowedCorsOrigins: uniqueHostnames(x.allowedCorsOrigins?.filter(defined) ?? []).join(`, `),
    allowedScopes: x.allowedScopes?.join(`, `),
  }))

  const loading = applicationQuery.loading
  const error = applicationQuery.error
  const refresh = () => {
    applicationQuery.refetch()
  }

  return (
    <Protected
      allowedRoles={[
        'IDENTITYSERVER_SUPERUSER',
        'IDENTITYSERVER_APPLICATION_ADMIN',
        'IDENTITYSERVER_ORGANIZATION_ADMIN',
      ]}
    >
      <Wrapper title='Applications'>
        <Box margin={2}>
          <Card>
            <CardHeader title='Applications' />
            <CardErrorContent onRetry={refresh} error={error?.message} />
            <DataTable
              toolbar={
                <TableRouteButton startIcon={<AddIcon />} to={`/applications/new`} children='Add New Application' />
              }
              columns={[
                { field: `clientId`, headerName: `Id`, flex: 1 },
                { field: `name`, headerName: `Name`, flex: 1 },
                { field: `allowedCorsOrigins`, headerName: `Origin`, flex: 1 },
                { field: `allowedScopes`, headerName: `Scope`, flex: 1 },
                {
                  field: 'isActive',
                  headerName: `Status`,
                  renderCell: row => <ActiveStatus label={row.row.active} active={row.row.isActive} />,
                },
              ]}
              data={rows ?? []}
              refresh={refresh}
              loading={loading}
            />
          </Card>
        </Box>
      </Wrapper>
    </Protected>
  )
}

const uniqueHostnames = (input: string[]) => {
  const output = new Set(
    input.map(url => {
      try {
        return new URL(url).hostname
      } catch (ex) {
        return url
      }
    }),
  )
  return Array.from(output.values()).sort((a, b) => a.localeCompare(b))
}
