import React from 'react'
import { ApolloError, ApolloQueryResult, ServerError, ServerParseError } from '@apollo/client'
import { Button, Collapse } from '@material-ui/core'
import { MColor, MFlexBlock, MFlexItem, MText } from '@mprise/react-ui'
import { formatError, GraphQLError } from 'graphql'

type SimpleQueryResult = {
  errors?: ReadonlyArray<GraphQLError>
  error?: ApolloError
  loading: boolean
  refetch: (variables?: any) => Promise<ApolloQueryResult<any>>
}

type SimpleMutationResult = {
  error?: ApolloError
  loading: boolean
}

export const QueryErrorMessage = ({ query }: { query: SimpleQueryResult | Array<SimpleQueryResult> }) => {
  query = Array.isArray(query) ? query : [query]

  const firstWithAnIssue = query.find(x => !x.loading && !!x.error)
  const messages = firstWithAnIssue?.error ? collectErrors(firstWithAnIssue.error) : []
  const handleRetry = () => firstWithAnIssue?.refetch()
  return (
    <Collapse in={!!firstWithAnIssue} unmountOnExit timeout={100}>
      <MFlexBlock bgColor={MColor.medium} variant='rounded' margin={0} padding={2}>
        <MFlexItem grow={1}>
          <MText block textVariant='content bold'>
            A problem occurred while requesting the information
          </MText>
          {messages.map(msg => (
            <MText block textVariant='small' style={{ whiteSpace: `pre-wrap` }}>
              {msg}
            </MText>
          ))}
        </MFlexItem>
        <MFlexItem shrink={0}>
          <Button variant='outlined' size='small' onClick={handleRetry}>
            Retry
          </Button>
        </MFlexItem>
      </MFlexBlock>
    </Collapse>
  )
}

export const MutationErrorMessage = ({
  mutation,
}: {
  mutation: SimpleMutationResult | Array<SimpleMutationResult>
}) => {
  mutation = Array.isArray(mutation) ? mutation : [mutation]

  const firstWithAnIssue = mutation.find(x => !x.loading && !!x.error)
  const messages = firstWithAnIssue?.error ? collectErrors(firstWithAnIssue.error) : []
  return (
    <Collapse in={!!firstWithAnIssue} unmountOnExit timeout={100}>
      <MFlexBlock bgColor={MColor.medium} variant='rounded' margin={0} padding={2}>
        <MFlexItem grow={1}>
          <MText block textVariant='content bold'>
            A problem occurred while submitting information
          </MText>
          {messages.map(msg => (
            <MText block textVariant='small' style={{ whiteSpace: `pre-wrap` }}>
              {msg}
            </MText>
          ))}
        </MFlexItem>
      </MFlexBlock>
    </Collapse>
  )
}

const formatNetworkError = (error: Error | ServerParseError | ServerError): string[] => {
  if ('result' in error) {
    //@ts-ignore
    const errors = error.result['errors']
    if (Array.isArray(errors)) {
      return errors.map(x => formatError(x as GraphQLError).message)
    } else {
      return [error.message]
    }
  } else {
    return [error.message]
  }
}

const collectErrors = (error: ApolloError): string[] => {
  return [
    error.graphQLErrors.map(x => formatError(x).message),
    error.networkError ? formatNetworkError(error.networkError) : [],
  ].flat()
}
