import { InMemoryCache } from '@apollo/client/cache'
import { ApolloClient, HttpLink } from '@apollo/client/core'
import { ApolloLink } from '@apollo/client/link/core'
import { RetryLink } from '@apollo/client/link/retry'
import { ApolloProvider } from '@apollo/client/react'
import { Fade } from '@material-ui/core'
import { MText, MTextColor } from '@mprise/react-ui'
import React, { useMemo } from 'react'

export const AuthorizedApolloClientProvider = ({ children }: { children: React.ReactNode }) => {
  const { client } = useMemo(() => createClient({}), [])

  if (client) {
    return <ApolloProvider client={client}>{children}</ApolloProvider>
  } else {
    // throw promise and leverage react suspense while waiting for settings?
    return (
      <Fade in timeout={2000} style={{ transitionDelay: `2s` }}>
        <MText block textColor={MTextColor.shadow} textVariant='header'>
          Loading configuration ...
        </MText>
      </Fade>
    )
  }
}

const createClient = ({}: {}) => {
  const link = createLink({})
  const cache = createCache()
  const client = new ApolloClient({
    cache,
    link,
    defaultOptions: {
      query: { fetchPolicy: `network-only` },
      watchQuery: { fetchPolicy: `cache-and-network`, nextFetchPolicy: `cache-first` },
    },
  })
  return { client }
}

const createCache = () => {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          my: {
            merge: true,
          },
        },
      },
    },
  })

  return cache
}

const createLink = ({}: {}) => {
  const retryLink = new RetryLink({ attempts: { max: 2 } })

  // BatchHttpLink not possible at the moment: hotchocolate server returns multiple queries as multipart
  const httpLink = new HttpLink({
    uri: `${window.location.origin}/graphql`,
    credentials: `include`,
  })
  // const httpLink = new BatchHttpLink({
  //   // batchInterval: 500, quickly test multipart issue
  //   // batchMax: 2, quickly test multipart issue
  //   uri: `${window.location.origin}/graphql`,
  //   credentials: `include`,
  // })

  const link = ApolloLink.from([retryLink, httpLink])

  return link
}
