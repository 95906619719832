import { Box } from '@material-ui/core'
import { Formik, MFieldConnector, MFieldInput, withFormikCompareFix } from '@mprise/react-ui'
import { Field } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  namedOperations,
  useGlobalApplicationsQuery,
  useKnownApplicationsLazyQuery,
  useSpecificTenantApplicationsQuery,
  useSpecificTenantQuery,
  useUpdateTenantMutation,
} from '../graphql/generated'
import { LoadingSwitchPanel } from '../organization/loading-switch-panel'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'
import { Alerts } from '../shared/alerts'
import { MutationErrorMessage } from '../shared/apollo'
import { CardErrorContent } from '../shared/card-error-content'
import { MFieldMultiSelect } from '../shared/mfield-multiselect'
import { FormikDialog } from '../shared/react-formik-dialog'
import { defined } from '../shared/typescript'
import { useLocalState } from '../utils'
import { knownLoginMethods, useLoginMethodSearch } from './login-methods'

type TenantForm = {
  name: string
  applicationIds: Array<string>
  loginMethods: Array<string>
}
const emptyTenantForm: TenantForm = {
  name: ``,
  applicationIds: [],
  loginMethods: [],
}

export const TenantEditDialog = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { organizationId, tenantId } = useParams() as { organizationId: string; tenantId: string }

  const tenantQuery = useSpecificTenantQuery({ variables: { tenantId } })
  const tenantApplicationsQuery = useSpecificTenantApplicationsQuery({ variables: { tenantId } })
  const tenant = tenantQuery.data?.tenants?.[0]
  const tenantApplications = useMemo(
    () => tenantApplicationsQuery.data?.tenants?.flatMap(x => x?.applications).filter(defined),
    [tenantApplicationsQuery.data],
  )
  const applicationQuery = useGlobalApplicationsQuery()
  const applications = applicationQuery.data?.applications

  const loginMethodSearch = useLoginMethodSearch()

  const [form] = useLocalState<TenantForm>(
    () =>
      withFormikCompareFix({
        name: tenant?.name ?? ``,
        applicationIds: tenantApplications?.map(x => x.id).filter(defined) ?? [],
        loginMethods: tenant?.loginMethods?.map(x => x?.method).filter(defined) ?? [],
      }),
    [tenant, tenantApplications],
  )

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const [updateTenant, updateTenantState] = useUpdateTenantMutation({
    refetchQueries: [namedOperations.Query.Me, namedOperations.Query.AllTenants],
  })
  const save = async (form: TenantForm) => {
    const updated = await updateTenant({
      variables: {
        tenantId,
        name: form.name ?? ``,
        applicationIds: form.applicationIds.filter(defined) ?? [],
        loginMethods: form.loginMethods,
      },
    })

    if (updated.data?.tenant?.update) {
      alerts.push(alertText.updated(`Tenant`), `success`)
      handleClose()
    }
  }

  const handleClose = () => {
    navigate(`/organization/${organizationId}/tenants`, { replace: true })
  }

  const applicationSearch = TenantEditDialog.useApplicationSearch()

  return (
    <>
      <Formik.Formik initialValues={form} enableReinitialize onSubmit={save}>
        <FormikDialog title='Edit Tenant' submit='Edit Tenant' onClose={handleClose} open minWidth='xs'>
          <Box minWidth={400}>
            <LoadingSwitchPanel loading={tenantQuery.loading || tenantApplicationsQuery.loading}>
              <SavingSwitchPanel mutations={[updateTenantState]}>
                <MutationErrorMessage mutation={updateTenantState} />
                <CardErrorContent error={tenantQuery.error?.message} />
                <Field component={MFieldConnector} name='name' label={t(`Name`)}>
                  <MFieldInput autoComplete='off' />
                </Field>
                <Field component={MFieldConnector} name='applicationIds' label={t(`Applications`)}>
                  <MFieldMultiSelect
                    margin='dense'
                    label=''
                    items={
                      applications?.filter(defined).map(x => ({
                        id: x.id ?? `TODO`,
                        name: x.name ?? x.id ?? `-`,
                        secondary: (x.name ? x.id : undefined) ?? undefined,
                      })) ?? []
                    }
                    {...applicationSearch}
                  />
                </Field>

                <Field component={MFieldConnector} name='loginMethods' label={t(`Login Methods`)}>
                  <MFieldMultiSelect margin='dense' label='' items={knownLoginMethods} {...loginMethodSearch} />
                </Field>
              </SavingSwitchPanel>
            </LoadingSwitchPanel>
          </Box>
        </FormikDialog>
      </Formik.Formik>
    </>
  )
}

TenantEditDialog.useApplicationSearch = () => {
  const [doQuery, query] = useKnownApplicationsLazyQuery()
  const [search, setSearch] = useState(``)
  useEffect(() => {
    doQuery({ variables: { text: search } })
  }, [search])
  const suggesions = query.data?.applications?.filter(defined).map(x => ({ id: x.id ?? ``, name: x.name ?? `` })) ?? []

  return {
    search,
    onSearch: setSearch,
    suggestions: suggesions,
  }
}
