import { CircularProgress, Fade } from '@material-ui/core'
import { MColor, MFlexBlock, MText, px } from '@mprise/react-ui'
import React from 'react'
import { SwitchTransition } from 'react-transition-group'

export const SavingSwitchPanel = ({
  mutations,
  savingAnyway,
  children,
}: {
  mutations: Array<{ loading: boolean }>
  savingAnyway?: boolean
  children: React.ReactNode
}) => {
  const loading = mutations.some(x => x.loading) || savingAnyway
  return (
    <SwitchTransition>
      <Fade key={Number(loading)}>
        {loading ? (
          <MFlexBlock justifyContent='center' alignItems='center' padding={10} gap={5}>
            <MText block textColor={MColor.primary}>
              <CircularProgress color='inherit' size={px(48)} />
            </MText>
            <MText block textColor={MColor.primary}>
              Submitting changes...
            </MText>
          </MFlexBlock>
        ) : (
          <div>{children}</div>
        )}
      </Fade>
    </SwitchTransition>
  )
}
