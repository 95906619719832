import { Field, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLicenseTemplatesQuery } from '../graphql/generated'
import { MFieldCheckbox } from '../shared/field-checkbox'
import { withFormikCompareFix } from '../shared/formik'
import { MFieldConnector } from '../shared/mfield-adapter'
import { MFieldAutoComplete } from '../shared/mfield-autocomplete'
import { FormikDialog } from '../shared/react-formik-dialog'
import { defined, Optional } from '../shared/typescript'
import { useLocalState } from '../utils'

export type SelectTemplateForm = {
  replace: boolean
  template: Optional<{ id: string; permissions: string[] }>
}

export const SelectTemplateDialog = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: (form: SelectTemplateForm | null) => void
}) => {
  const { t } = useTranslation()
  const templateQuery = useLicenseTemplatesQuery()
  const templates = templateQuery.data?.licenseTemplates?.filter(defined).map(x => ({
    id: x.id,
    name: x.name,
    permissions: x.permissions,
  }))

  const handleSave = async (form: SelectTemplateForm) => {
    onClose(form)
  }

  const handleClose = () => {
    onClose(null)
  }

  const [initialValues] = useLocalState(
    () =>
      withFormikCompareFix({
        replace: false,
        template: null,
      }),
    [open],
  )

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSave}>
      <FormikDialog title='Apply License Template' submit='Select' onClose={handleClose} open={open}>
        <Field component={MFieldConnector} name='template' label={t(`License Template`)}>
          <MFieldAutoComplete items={templates ?? []} label={item => item.name} />
        </Field>
        <Field component={MFieldConnector} name='replace' label={t(`Replace`)}>
          <MFieldCheckbox label='Replace all license permissions instead of adding absent permissions' />
        </Field>
      </FormikDialog>
    </Formik>
  )
}
