import styled from '@emotion/styled'
import { MBlock, MColor, MText } from '@mprise/react-ui'
import { useFormikContext } from 'formik'
import React from 'react'

export const ValidationIssues = () => {
  const formik = useFormikContext()
  const errors = unwind(formik.errors)
  return errors.length ? (
    <ErrorMessage>
      {errors.map((text, idx) => (
        <StyledP key={idx}>{text}</StyledP>
      ))}
    </ErrorMessage>
  ) : null
}

const StyledP = styled.p`
  margin: 0;
`

export const ErrorMessage = ({ children }: { children: React.ReactNode }) => {
  return (
    <MBlock bgColor={MColor.medium} variant='rounded' margin={0} padding={2}>
      <MText block textVariant='content bold'>
        {children}
      </MText>
    </MBlock>
  )
}

const unwind = (errors: string | string[] | Record<string, any>): string[] => {
  if (typeof errors === `string`) {
    return [errors]
  } else if (Array.isArray(errors)) {
    return errors.flatMap(x => unwind(x))
  } else {
    return unwind(Array.from(Object.values(errors)))
  }
}
